import axios from 'axios'

export default {
  state: {
    availableApps: [],
    categories: [],
    shaking: false
  },
  mutations: {
    SET_AVAILABLE_APPS(state, availableApps) {
      state.availableApps = availableApps
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    }
  },
  actions: {
    loadAppsAvailableForCurrentUser({ commit }) {
      return axios.get('/v1/apps').then(data => {
        // Filter out apps that have false set in show_in_menu
        const filteredApps = data.data

        commit('SET_AVAILABLE_APPS', filteredApps)
      })
    },
    ensureAvailableAppsLoaded({ state, dispatch }) {
      if (state.availableApps == null || state.availableApps.length == 0) {
        dispatch('loadAppsAvailableForCurrentUser')
      }
    },
    setAppCategories({ commit }, categories) {
      commit('SET_CATEGORIES', categories)
    }
  },
  getters: {
    availableApps(state) {
      return state.availableApps
    },
    appCategories(state) {
      return state.categories
    },
    getAppsForCategory: state => categoryId => {
      let filteredApps = state.availableApps.filter(
        app => app.appCategory == categoryId && app.showInMenu
      )
      return filteredApps
    },
    getAppsWithSettings(state) {
      let filteredApps = state.availableApps.filter(item => item.webSettings)
      console.debug(filteredApps)

      return filteredApps
    },
    userHasAppById: state => appId => {
      return (
        state.availableApps.filter(item => item.appId === appId).length != 0
      )
    }
  }
}
