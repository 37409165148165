import axios from 'axios'

export default {
  state: {
    user: {
      username: '',
      email: '',
      userId: '',
      img_base64: '',
      userLvl: 0
    },
    settings_crm: undefined,
    settings_cal: undefined,
    settings_dashboard: undefined
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user.username = userData.username
      state.user.email = userData.email
      state.user.userId = userData.userId
      state.user.userLvl = userData.userLvl
    },
    CLEAR_USER_DATA(state) {
      state.user = {
        username: '',
        email: '',
        userId: '',
        img_base64: ''
      }
    },
    SET_USER_IMG_BASE64(state, img_data) {
      state.user.img_base64 = img_data
    },
    SET_USER_SETTINGS(state, settings) {
      state.settings_crm = settings.userSettings['WEB_crm']
      state.settings_cal = settings.userSettings['WEB_cal']
      state.settings_dashboard = settings.userSettings['WEB_dashboard']
    }
  },
  actions: {
    loadUserData({ commit }) {
      return axios.get('/v1/user').then(response => {
        commit('SET_USER_DATA', response.data)
      })
    },
    clearUserData({ commit }) {
      commit('CLEAR_USER_DATA')
    },
    loadUserImageBase64({ commit }) {
      return axios.get('/v1/user/picture').then(response => {
        commit('SET_USER_IMG_BASE64', response.data)
      })
    },
    loadUserSettings({ commit }) {
      return axios.get('/v1/user/settings').then(response => {
        commit('SET_USER_SETTINGS', response.data)
      })
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    user_img(state) {
      return state.user.img
    },
    user_img_base64(state) {
      return state.user.img_base64
    },
    settings_cal(state) {
      return state.settings_cal
    },
    settings_dashboard(state) {
      return state.settings_dashboard
    }
  }
}
