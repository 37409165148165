import contactPersonService from '@/services/crm/contactPersonService'

export default {
  state: {
    contacts: [],
    functions: [],
    departments: []
  },
  mutations: {
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts.map(c => {
        if (c?.abteilung == null) return c
        else
          return {
            ...c,
            department: {
              id: null,
              department: c.abteilung
            }
          }
      })
    },
    SET_FUNCTIONS(state, functions) {
      state.functions = functions.sort()
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    }
  },
  actions: {
    async loadContacts({ commit }, { lfdnr }) {
      await contactPersonService
        .getContactPersonsForAddress(lfdnr)
        .then(response => {
          commit('SET_CONTACTS', response)
        })
    },
    saveContact({ dispatch }, { lfdnr, contact }) {
      contactPersonService
        .createContactPersonForAddress(lfdnr, contact)
        .then(() => {
          dispatch('loadContacts', { lfdnr })
          dispatch('getFunktionen')
          dispatch('getDepartments')
        })
    },
    updateContact({ dispatch }, { lfdnr, contact }) {
      contactPersonService
        .updateContactPersonForAddress(lfdnr, contact)
        .then(() => {
          dispatch('loadContacts', { lfdnr })
          dispatch('getFunktionen')
          dispatch('getDepartments')
        })
    },
    deleteContact({ dispatch }, { lfdnr, key }) {
      contactPersonService
        .deleteContactPersonForAddress(lfdnr, key)
        .then(() => {
          dispatch('loadContacts', { lfdnr })
        })
    },
    getFunktionen({ commit }) {
      contactPersonService.getFunctions().then(response => {
        commit('SET_FUNCTIONS', response)
      })
    },
    getDepartments({ commit }) {
      contactPersonService.getDepartments().then(response => {
        commit('SET_DEPARTMENTS', response)
      })
    }
  },

  getters: {}
}
