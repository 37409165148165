import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "dialogType": "warning",
      "cancelButton": false,
      "max-width": "650px"
    },
    on: {
      "close": function ($event) {
        _vm.serialReservedDataDialog = false;
      },
      "click-outside": function ($event) {
        _vm.serialReservedDataDialog = false;
      },
      "keydown-esc": function ($event) {
        _vm.serialReservedDataDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Serienterminkonflikt ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VContainer, {
          attrs: {
            "fluid": ""
          }
        }, _vm._l(_vm.conflicts, function (conflict) {
          return _c(VCard, {
            key: conflict.appointment.key,
            staticClass: "mx-10 my-5",
            staticStyle: {
              "border": "1px solid #757575"
            },
            attrs: {
              "elevation": "0"
            }
          }, [_c(VCardTitle, [_vm._v(" " + _vm._s(conflict.appointment.title) + " ")]), _c(VCardText, [conflict.reservedAddresses.length > 0 ? _c('div', [_c('p', [_vm._v("Adresskonflikt:")]), _c('p', [_vm._v(_vm._s(conflict.reservedAddresses[0].name))])]) : _vm._e(), conflict.reservedObjects.length > 0 ? _c('div', [_c('p', [_vm._v("Objektkonflikt")]), _c('p', [_vm._v(_vm._s(conflict.reservedObjects[0].name))])]) : _vm._e(), conflict.reservedResources.length > 0 ? _c('div', [_c('p', [_vm._v("Ressourcenkonflikt")]), _c('p', [_vm._v(_vm._s(conflict.reservedResources[0].name))])]) : _vm._e()]), _c(VCardActions, [_c('LBAButton', {
            attrs: {
              "buttonType": "warning",
              "buttonStyle": "outlined"
            },
            on: {
              "click": function ($event) {
                return _vm.perform('CREATE_WITHOUT', conflict);
              }
            }
          }, [_c(VIcon, [_vm._v(" mdi-close ")]), _vm._v(" Kollision entfernen ")], 1), _c(VSpacer), _c('LBAButton', {
            attrs: {
              "buttonType": "warning",
              "buttonStyle": "outlined"
            },
            on: {
              "click": function ($event) {
                return _vm.perform('CREATE', conflict);
              }
            }
          }, [_c(VIcon, [_vm._v(" mdi-check ")]), _vm._v(" Kollision Übernehmen ")], 1)], 1)], 1);
        }), 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBAButton', {
          attrs: {
            "buttonType": "warning",
            "buttonStyle": "outlined"
          },
          on: {
            "click": function ($event) {
              return _vm.performAll('CREATE_WITHOUT');
            }
          }
        }, [_c(VIcon, [_vm._v(" mdi-close ")]), _vm._v(" Alle entfernen ")], 1), _c('LBAButton', {
          attrs: {
            "buttonType": "warning",
            "buttonStyle": "outlined"
          },
          on: {
            "click": function ($event) {
              return _vm.performAll('CREATE');
            }
          }
        }, [_c(VIcon, [_vm._v(" mdi-check ")]), _vm._v(" Alle übernehmen ")], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.serialReservedDataDialog,
      callback: function ($$v) {
        _vm.serialReservedDataDialog = $$v;
      },
      expression: "serialReservedDataDialog"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };