import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "persistent": true,
      "maxWidth": 750,
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        return _vm.closeDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Bild Hochladen")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('h3', [_vm._v("Art der Datei:")]), _c(VRadioGroup, {
          attrs: {
            "row": ""
          },
          model: {
            value: _vm.imageType,
            callback: function ($$v) {
              _vm.imageType = $$v;
            },
            expression: "imageType"
          }
        }, [_c(VRadio, {
          attrs: {
            "label": _vm.picTypes[0],
            "value": "0"
          }
        }), _c(VRadio, {
          attrs: {
            "label": _vm.picTypes[1],
            "value": "1"
          }
        }), _c(VRadio, {
          attrs: {
            "label": _vm.picTypes[2],
            "value": "2"
          }
        }), _c(VRadio, {
          attrs: {
            "label": _vm.picTypes[3],
            "value": "3"
          }
        }), _c(VRadio, {
          attrs: {
            "label": _vm.picTypes[4],
            "value": "4"
          }
        })], 1), _c('label', {
          staticClass: "custom-file-upload"
        }, [_c('input', {
          ref: "file",
          attrs: {
            "type": "file",
            "id": "file",
            "accept": ".jpg,.jpeg,.png"
          },
          on: {
            "change": [_vm.changePrev, function ($event) {
              return _vm.handleFileUpload();
            }]
          }
        })]), _vm.img_prevurl != '' ? _c('div', {
          attrs: {
            "id": "preview"
          }
        }, [_c(VDivider, {
          staticClass: "mt-8"
        }), _c('h3', {
          staticClass: "mt-8 mb-2"
        }, [_vm._v("Vorschau:")]), _c('img', {
          attrs: {
            "src": _vm.img_prevurl
          }
        })], 1) : _vm._e(), _c('ErrorDialog', {
          attrs: {
            "failMessage": _vm.failMessage,
            "opened": _vm.ErrorPictureUpload
          },
          on: {
            "closeDialog": function ($event) {
              _vm.ErrorPictureUpload = false;
            }
          }
        }), _c('ErrorDialog', {
          attrs: {
            "failMessage": 'Nur Bilder erlaubt!',
            "opened": _vm.ErrorWrongFileExtension
          },
          on: {
            "closeDialog": function ($event) {
              _vm.ErrorWrongFileExtension = false;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_vm.img_prevurl != '' ? _c('LBAButton', {
          attrs: {
            "buttonType": 'error',
            "buttonStyle": 'text'
          },
          on: {
            "click": function ($event) {
              _vm.img_prevurl = '';
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Entfernen ")];
            },
            proxy: true
          }], null, false, 2645745151)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_vm.address != null && _vm.img_prevurl != '' ? _c('LBAButton', {
          attrs: {
            "buttonType": 'primary',
            "buttonStyle": 'default'
          },
          on: {
            "click": function ($event) {
              return _vm.submitFile();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_vm._v(" Hochladen "), _c(VIcon, {
                staticClass: "ml-2"
              }, [_vm._v("mdi-upload")])];
            },
            proxy: true
          }], null, false, 1742718479)
        }) : _vm._e(), _vm.loadingPicUpload == true ? _c(VProgressCircular, {
          staticClass: "ml-5",
          attrs: {
            "indeterminate": "",
            "color": "green"
          }
        }) : _vm._e(), _c(VSnackbar, {
          scopedSlots: _vm._u([{
            key: "action",
            fn: function ({
              attrs
            }) {
              return [_c('LBAButton', _vm._b({
                attrs: {
                  "buttonType": 'primary',
                  "buttonStyle": 'text'
                },
                on: {
                  "click": function ($event) {
                    _vm.SuccessSnackbarPicture = false;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "content",
                  fn: function () {
                    return [_vm._v(" Schließen ")];
                  },
                  proxy: true
                }], null, true)
              }, 'LBAButton', attrs, false))];
            }
          }]),
          model: {
            value: _vm.SuccessSnackbarPicture,
            callback: function ($$v) {
              _vm.SuccessSnackbarPicture = $$v;
            },
            expression: "SuccessSnackbarPicture"
          }
        }, [_vm._v(" Foto wurde hochgeladen ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.opened,
      callback: function ($$v) {
        _vm.opened = $$v;
      },
      expression: "opened"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };