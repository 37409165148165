import Header from '@/components/core/Header';
import NavBar from '@/components/core/NavBar.vue';
import Footer from '@/components/core/Footer';
import { mapGetters } from 'vuex';
//import BreadCrumbComponent from '@/components/core/BreadCrumbComponent.vue'

import swUpdate from './mixins/swUpdate';
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    NavBar
    //BreadCrumbComponent
  },
  mixins: [swUpdate],
  data: () => ({
    settings: {
      default: null
    },
    drawer: false,
    isOnline: navigator.isOnline,
    lastIsOnlineState: true
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    drawerChange(newVal) {
      this.drawer = newVal;
    },
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.isOnline = type === 'online';
    }
  },
  computed: {
    ...mapGetters(['loggedIn'])
  },
  mounted() {
    // falls User die Seite refreshed und ein Token gespeichert ist, wird dieser Token für zukünftige requests benutzt
    this.$store.dispatch('loadAuthFromLocalStorage');
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  watch: {
    isOnline(v) {
      if (!v) {
        this.$toast.error('Aktivieren Sie Ihre Internetverbindung um LBA-WEB verwenden zu können!', {
          timeout: false,
          closeButton: false,
          draggable: false,
          closeOnClick: false,
          id: 'isOffline'
        });
        this.lastIsOnlineState = false;
      }
      if (v && !this.lastIsOnlineState) {
        this.lastIsOnlineState = true;
        this.$toast.dismiss('isOffline');
        this.$toast.success('Internetverbindung hergestellt!');
      }
    }
  }
};