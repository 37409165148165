import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VApp, {
    staticStyle: {
      "font-family": "'Helvetica Neue LT Std'"
    }
  }, [_c(VSnackbar, {
    attrs: {
      "bottom": "",
      "right": "",
      "value": _vm.updateExists,
      "timeout": -1,
      "color": "warning"
    }
  }, [_c('span', {
    staticClass: "pa-3"
  }, [_vm._v("Eine neue Version ist verfügbar.")]), _c(VBtn, {
    staticClass: "ml-4",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.refreshApp
    }
  }, [_vm._v(" Jetzt Aktualisieren ")])], 1), _c('Header', {
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "drawerChange": _vm.drawerChange
    }
  }), _vm.loggedIn ? _c('NavBar', {
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "drawerChange": _vm.drawerChange
    }
  }) : _vm._e(), _c(VMain, {
    staticClass: "white"
  }, [_c('router-view')], 1), !_vm.isMobile() ? _c('Footer') : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };