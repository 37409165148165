import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('LBADialog', {
    attrs: {
      "max-width": "700",
      "scrollable": ""
    },
    on: {
      "close": function ($event) {
        _vm.serialAppointmentSummary = false;
      },
      "click-outside": function ($event) {
        _vm.serialAppointmentSummary = false;
      },
      "keydown-esc": function ($event) {
        _vm.serialAppointmentSummary = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Terminserie Zusammenfassung ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VSimpleTable, [_c('thead', [_c('td', {
          staticClass: "pl-4"
        }, [_c('b', [_vm._v("Nr.")])]), _c('td', {
          staticClass: "pl-4"
        }, [_c('b', [_vm._v("Von")])]), _c('td', {
          staticClass: "pl-4"
        }, [_c('b', [_vm._v("Bis")])])]), _c('tbody', _vm._l(_vm.serialPreviews, function (appointment, index) {
          return _c('tr', {
            key: appointment.start,
            staticClass: "text-p pa-2"
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(appointment.start, false)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(appointment.end, false)) + " ")])]);
        }), 0)])];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": _vm.createSerialAppointment
          }
        }, [_vm._v("Anlegen")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.serialAppointmentSummary,
      callback: function ($$v) {
        _vm.serialAppointmentSummary = $$v;
      },
      expression: "serialAppointmentSummary"
    }
  }), _c('SerialReservedDataDialog', {
    ref: "serialResDataDialog",
    on: {
      "close": _vm.close
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };