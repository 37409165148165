import axios from 'axios'

export default {
  getContactPersonsForAddress(lfdnr) {
    return axios
      .get(`/v1/crm/addresses/${lfdnr}/contactPersons`)
      .then(response => response.data)
  },

  createContactPersonForAddress(lfdnr, contactPerson) {
    return axios
      .post(`/v1/crm/addresses/${lfdnr}/contactPersons`, contactPerson)
      .then(response => response.data)
  },

  updateContactPersonForAddress(lfdnr, contactPerson) {
    return axios
      .put(`/v1/crm/addresses/${lfdnr}/contactPersons`, contactPerson)
      .then(response => response.data)
  },

  deleteContactPersonForAddress(lfdnr, key) {
    return axios
      .delete(`/v1/crm/addresses/${lfdnr}/contactPersons`, { data: key })
      .then(response => response.data)
  },

  getFunctions() {
    return axios
      .get(`/v1/crm/contactPersons/functions`)
      .then(response => response.data)
  },

  getDepartments() {
    return axios
      .get(`/v1/crm/contactPersons/departments`)
      .then(response => response.data)
  }
}
