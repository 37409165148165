import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": "",
      "max-width": "367px",
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        _vm.DateTimePicker = false;
      },
      "click-outside": function ($event) {
        _vm.DateTimePicker = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Datum und Uhrzeit wählen ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VTabs, {
          attrs: {
            "fixed-tabs": "",
            "icons-and-text": ""
          },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v;
            },
            expression: "tab"
          }
        }, [_c(VTab, {
          key: "date",
          attrs: {
            "default": ""
          }
        }, [_vm._v(_vm._s(_vm.dateString)), _c(VIcon, [_vm._v("mdi-calendar-range")])], 1), _c(VTab, {
          key: "time",
          attrs: {
            "disabled": _vm.allDay
          }
        }, [_vm._v(_vm._s(_vm.time) + " "), _c(VIcon, [_vm._v("mdi-clock")])], 1)], 1), _c(VTabsItems, {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v;
            },
            expression: "tab"
          }
        }, [_c(VTabItem, {
          key: "date"
        }, [_c(VDatePicker, {
          attrs: {
            "no-title": "",
            "scrollable": "",
            "first-day-of-week": "1",
            "color": "primary",
            "min": _vm.minimumDate
          },
          on: {
            "change": function ($event) {
              return _vm.goToTime();
            }
          },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v;
            },
            expression: "date"
          }
        })], 1), _c(VTabItem, {
          key: "time"
        }, [_c(VRow, {
          attrs: {
            "dense": ""
          }
        }, [_c(VCol, {
          staticClass: "d-flex flex-column align-center"
        }, [_c('vue-timepicker'), _c(VTextField, {
          staticClass: "text-justify text-center",
          staticStyle: {
            "width": "55px"
          },
          attrs: {
            "maxlength": "5",
            "type": "time",
            "step": "300"
          },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v;
            },
            expression: "time"
          }
        }), _vm.blockSave ? _c(VAlert, {
          staticStyle: {
            "margin-left": "10px",
            "margin-right": "10px"
          },
          attrs: {
            "type": "warning"
          }
        }, [_vm._v(" Die Uhrzeit darf nur in 5 Minuten Schritten gespeichert werden. "), _c('div', {
          staticClass: "mt-2 d-flex justify-space-around"
        }, [_c('LBAButton', {
          attrs: {
            "buttonStyle": "outlined"
          },
          on: {
            "click": _vm.setRoundDown
          }
        }, [_vm._v(" " + _vm._s(_vm.roundToNearestFiveMinutes('down')) + " ")]), _c('LBAButton', {
          attrs: {
            "buttonStyle": "outlined"
          },
          on: {
            "click": _vm.setRoundUp
          }
        }, [_vm._v(" " + _vm._s(_vm.roundToNearestFiveMinutes('up')) + " ")])], 1)]) : _vm._e()], 1)], 1), _c(VRow, {
          staticClass: "mt-0",
          attrs: {
            "dense": ""
          }
        }, [_c(VCol, {
          staticClass: "d-flex flex-column align-center"
        }, [_c(VTimePicker, {
          attrs: {
            "allowed-minutes": _vm.allowedStep,
            "format": "24hr",
            "color": "primary",
            "no-title": ""
          },
          model: {
            value: _vm.time,
            callback: function ($$v) {
              _vm.time = $$v;
            },
            expression: "time"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              return _vm.save();
            }
          }
        }, [_vm._v("Fertig")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.DateTimePicker,
      callback: function ($$v) {
        _vm.DateTimePicker = $$v;
      },
      expression: "DateTimePicker"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };