import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "activator",
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._t("activator")], 2), _c(VDialog, {
    attrs: {
      "width": _vm.width,
      "scrollable": _vm.scrollable,
      "max-width": _vm.maxWidth,
      "persistent": _vm.persistent || _vm.permanent,
      "hide-overlay": _vm.hideOverlay
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.escPressed.apply(null, arguments);
      },
      "click:outside": _vm.clickedOutside
    },
    model: {
      value: _vm.internalValue,
      callback: function ($$v) {
        _vm.internalValue = $$v;
      },
      expression: "internalValue"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "ma-0 pa-0"
  }, [_c(VToolbar, {
    class: {
      'primary--text': _vm.dialogType === 'default',
      'success--text': _vm.dialogType === 'success',
      'warning--text': _vm.dialogType === 'warning',
      'error--text': _vm.dialogType === 'error'
    },
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "top": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._t("title")], 2), [!_vm.permanent ? _c(VIcon, {
    attrs: {
      "color": _vm.dialogType === 'success' ? 'success' : _vm.dialogType === 'warning' ? 'warning' : _vm.dialogType === 'error' ? 'error' : 'primary'
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("mdi-close")]) : _vm._e()]], 2)], 1), _c(VDivider, {
    class: {
      'primary thick-divider': _vm.dialogType === 'default',
      'success thick-divider': _vm.dialogType === 'success',
      'warning thick-divider': _vm.dialogType === 'warning',
      'error thick-divider': _vm.dialogType === 'error'
    }
  }), _c(VCardText, {
    staticClass: "mt-4",
    class: {
      'text-center': _vm.centeredContent
    }
  }, [_vm._t("content")], 2), _c(VCardActions, [_c(VSpacer), _vm.cancelButton && !_vm.permanent ? _c('LBAButton', {
    staticClass: "mr-2",
    attrs: {
      "buttonType": 'error',
      "buttonStyle": 'text'
    },
    on: {
      "click": function ($event) {
        return _vm.cancelDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_vm._v("Abbrechen")];
      },
      proxy: true
    }], null, false, 857291074)
  }) : _vm._e(), _vm._t("actions"), _vm._t("success")], 2)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };