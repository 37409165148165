import HomeService from '@/services/home/homeService.js';
import Widget from '@/components/home/Widget.vue';
export default {
  data: function () {
    return {
      failedLogins: null
    };
  },
  components: {
    Widget
  },
  async mounted() {
    this.failedLogins = await HomeService.getFailedLogins();
  }
};