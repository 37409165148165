import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "dialogType": "warning",
      "persistent": "",
      "max-width": "650px"
    },
    on: {
      "close": _vm.reservedDataDialog
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Terminkonflikt ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm.reservedData != null ? [_c('div', {
          attrs: {
            "align": "center"
          }
        }, [_c('span', {
          staticClass: "text-h4"
        }, [_vm._v(_vm._s(_vm.reservedData.appointment.title))])]), _c(VContainer, {
          attrs: {
            "fluid": ""
          }
        }, [_c(VRow, [_c(VCol, {
          attrs: {
            "cols": "6"
          }
        }, [_c(VCard, {
          staticStyle: {
            "border": "1px solid #757575"
          },
          attrs: {
            "elevation": "0"
          }
        }, [_c(VCardText, [_c('div', [_vm.reservedData.reservedAddresses.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-account ")]), _vm._v(" Adressen "), _vm._l(_vm.reservedData.reservedAddresses, function (a) {
          return _c('span', {
            key: a.key.lfdnr,
            staticClass: "text-decoration-line-through red--text class font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(a.name) + " ")]);
        })], 2) : _vm._e(), _vm.reservedData.reservedResources.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-account-tie ")]), _vm._v(" Ressourcen ")], 1) : _vm._e(), _vm._l(_vm.reservedData.reservedResources, function (r) {
          return _c('span', {
            key: r.key.lfdnr,
            staticClass: "text-decoration-line-through red--text class font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(r.name) + " ")]);
        }), _vm.reservedData.reservedObjects.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-package-variant-closed ")]), _vm._v(" Objekte "), _vm._l(_vm.reservedData.reservedObjects, function (o) {
          return _c('span', {
            key: o.key.lfdnr,
            staticClass: "text-decoration-line-through red--text class font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(o.description) + " ")]);
        })], 2) : _vm._e(), _c('div', {
          staticClass: "d-flex justify-center"
        }, [_c('LBAButton', {
          attrs: {
            "buttonType": "warning",
            "buttonStyle": "outlined"
          },
          on: {
            "click": function ($event) {
              return _vm.perform('CREATE_WITHOUT');
            }
          }
        }, [_c(VIcon, {
          staticClass: "mr-2 ml-n2"
        }, [_vm._v(" mdi-close ")]), _vm._v(" Kollision entfernen ")], 1)], 1)], 2)])], 1)], 1), _c(VCol, {
          attrs: {
            "cols": "6"
          }
        }, [_c(VCard, {
          staticStyle: {
            "border": "1px solid #757575"
          },
          attrs: {
            "elevation": "0"
          }
        }, [_c(VCardText, [_c('div', [_vm.reservedData.reservedAddresses.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-account ")]), _vm._v(" Adressen "), _vm._l(_vm.reservedData.reservedAddresses, function (a) {
          return _c('span', {
            key: a.key.lfdnr,
            staticClass: "font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(a.name) + " ")]);
        })], 2) : _vm._e(), _vm.reservedData.reservedResources.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-account-tie ")]), _vm._v(" Ressourcen ")], 1) : _vm._e(), _vm._l(_vm.reservedData.reservedResources, function (r) {
          return _c('span', {
            key: r.key.lfdnr,
            staticClass: "font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(r.name) + " ")]);
        }), _vm.reservedData.reservedObjects.length !== 0 ? _c('p', {
          staticClass: "font-weight-bold"
        }, [_c(VIcon, {
          staticClass: "mt-n1 ml-n1",
          attrs: {
            "dense": ""
          }
        }, [_vm._v(" mdi-package-variant-closed ")]), _vm._v(" Objekte "), _vm._l(_vm.reservedData.reservedObjects, function (o) {
          return _c('span', {
            key: o.key.lfdnr,
            staticClass: "font-weight-regular"
          }, [_c('br'), _vm._v(" " + _vm._s(o.description) + " ")]);
        })], 2) : _vm._e(), _c('div', {
          staticClass: "d-flex justify-center"
        }, [_c('LBAButton', {
          attrs: {
            "buttonType": "warning",
            "buttonStyle": "outlined"
          },
          on: {
            "click": function ($event) {
              return _vm.perform('CREATE');
            }
          }
        }, [_c(VIcon, {
          staticClass: "mr-2 ml-n2"
        }, [_vm._v(" mdi-check ")]), _vm._v(" Kollision Übernehmen ")], 1)], 1)], 2)])], 1)], 1)], 1)], 1)] : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.reservedDataDialog,
      callback: function ($$v) {
        _vm.reservedDataDialog = $$v;
      },
      expression: "reservedDataDialog"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };