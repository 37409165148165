import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Widget', [_vm.tasks.length > 0 ? _c('div', [_c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.tasks,
      "items-per-page": 20,
      "sort-by": ['dueDate', 'lfdnr'],
      "hide-default-footer": ""
    },
    on: {
      "click:row": item => _vm.openTask(item.lfdnr)
    },
    scopedSlots: _vm._u([{
      key: "item.lfdnr",
      fn: function ({
        item
      }) {
        return [_c(VChip, {
          attrs: {
            "color": _vm.getRowColor(item, 'primary')
          }
        }, [_vm._v(" " + _vm._s(item.lfdnr) + " ")])];
      }
    }, {
      key: "item.dueDate",
      fn: function ({
        item
      }) {
        return [_c('div', [_c(VIcon, {
          staticClass: "pr-1 mt-n1",
          attrs: {
            "color": _vm.getRowColor(item, 'primary')
          }
        }, [_vm._v(" mdi-clock ")]), _vm._v(" " + _vm._s(_vm.styleDate(item.dueDate)) + " ")], 1)];
      }
    }], null, false, 1934442583)
  })], 1) : _c('div', [_c('div', [_c('img', {
    staticClass: "pa-3 centerElement widgetNoDataPlaceholder",
    attrs: {
      "src": require("@/assets/svg/undraw_completing_re_i7ap.svg"),
      "alt": "Task-finished"
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };