import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Widget', [_c(VDataTable, {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.openPositions,
      "hide-default-header": "",
      "hide-default-footer": "",
      "items-per-page": _vm.openPositions.length,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VTextField, {
          staticClass: "mb-n5",
          attrs: {
            "outlined": "",
            "dense": "",
            "label": "Suche"
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }, {
      key: "item.betrag",
      fn: function ({
        item
      }) {
        return [_vm._v(" " + _vm._s(_vm.format(item.betrag)))];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };