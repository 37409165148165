import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VExpansionPanel, {
    class: {
      'border-left-primary-3': _vm.sideBar === true
    },
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.clicked
    }
  }, [_vm.titleType === 'default' ? _c(VExpansionPanelHeader, {
    class: {
      'primary--text': _vm.textColor === 'default',
      'primary--text': _vm.textColor === 'primary',
      'black--text': _vm.textColor === 'black',
      'success--text': _vm.textColor === 'success',
      'warning--text': _vm.textColor === 'warning',
      'error--text': _vm.textColor === 'error',
      'grey--text': _vm.textColor === 'grey',
      'grey lighten-3': _vm.bgColor === 'grey',
      white: _vm.bgColor === 'white'
    }
  }, [_vm._t("title")], 2) : _vm.titleType === 'headline' ? _c(VExpansionPanelHeader, {
    staticClass: "font-weight-black text-h5",
    class: {
      'primary--text': _vm.textColor === 'default',
      'primary--text': _vm.textColor === 'primary',
      'black--text': _vm.textColor === 'black',
      'success--text': _vm.textColor === 'success',
      'warning--text': _vm.textColor === 'warning',
      'error--text': _vm.textColor === 'error',
      'grey--text': _vm.textColor === 'grey',
      'grey lighten-3': _vm.bgColor === 'grey',
      white: _vm.bgColor === 'white'
    }
  }, [_vm._t("title")], 2) : _vm._e(), _c(VExpansionPanelContent, {
    staticClass: "pt-4",
    class: {
      'grey lighten-3': _vm.bgColor === 'grey',
      white: _vm.bgColor === 'white'
    }
  }, [_vm._t("content"), _c('div', {
    staticClass: "d-flex justify-end"
  }, [_vm._t("actions")], 2)], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };