import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  data: () => ({
    selectedDate: '',
    date: '',
    dateFormatted: null,
    menu: false
  }),
  props: {
    value: {
      type: [String, Date],
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Datum wählen'
    },
    dense: {
      type: Boolean,
      default: false
    },
    //TODO: add dateFormat property
    iconPosition: {
      type: String,
      default: 'right'
    },
    minimumDate: {
      type: [String, Date],
      default: null
    },
    maximumDate: {
      type: [String, Date],
      default: null
    }
  },
  computed: {
    ruleSet() {
      // Combine the legalDate rule with the custom rules provided via the :rules prop
      return [this.legalDate, ...this.rules];
    }
  },
  methods: {
    datePicked() {
      const valid = this.ruleSet.every(rule => rule(this.formatDate(this.date)) === true);
      if (!valid) return;
      this.$emit('datePicked', this.date);
    },
    parseDate(date) {
      if (!date) return null;
      if (date.includes('.')) {
        return this.parseDottedDate(date);
      } else {
        return this.parseShortDate(date);
      }
    },
    parseDottedDate(date) {
      //e.g.: 13.01.1990
      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    parseShortDate(date) {
      //e.g.: 13011990
      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4);
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return '';
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    legalDate(value) {
      // Handle null or undefined value
      if (value === null || value === undefined) {
        return 'Kein gültiges Datum!';
      }
      if (value == '') {
        return true;
      }
      const [day, month, year] = value.split('.');
      return dateTimeService.isValidDate(day, month, year) || 'Kein gültiges Datum!';
    },
    emitChange() {
      const valid = this.ruleSet.every(rule => rule(this.formatDate(this.date)) === true);
      if (valid) this.$emit('input', this.date);
    }
  },
  watch: {
    value(newValue) {
      this.date = newValue; // Update selectedDate when the value prop changes
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    }
  },
  mounted() {
    // Initialize selectedDate when the component is mounted
    this.date = this.value;
  }
};