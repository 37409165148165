import { DateTime } from 'luxon'

export default {
  getTimeStringFromDateTimeString(dateTime) {
    console.info(dateTime)
    return dateTime.substring(11, 16)
  },
  getTimeStringFromDateObject(date) {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE)
  },
  getDateStringFromDateTimeString(dateTime) {
    return dateTime.substring(0, 10)
  },
  getLocalTimeWithDate(date) {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_HUGE)
  },
  getLocalTimeWithDateAndTime(date) {
    return DateTime.fromJSDate(date)
      .setLocale('de')
      .toFormat('EEEE, dd.MM.yyyy HH:mm')
  },
  getGermanDateFormat(date) {
    var options = {
      dateStyle: 'full',
      timeStyle: 'short'
    }
    return new Date(date).toLocaleString('de-DE', options)
  },
  getGermanDateFormatWithoutTime(date) {
    var options = {
      dateStyle: 'full'
    }
    return new Date(date).toLocaleString('de-DE', options)
  },
  getShortGermanDate(date) {
    var options = {
      dateStyle: 'short'
    }
    return new Date(date).toLocaleString('de-DE', options)
  },
  getShortGermanDateWithNumericYear(date) {
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      hour12: false,
      minute: '2-digit'
    }
    return new Date(date).toLocaleString('de-DE', options)
  },
  getDurationSplit(duration) {
    var split = {
      minutes: duration % 60,
      days: Math.floor(duration / 1440),
      hours: Math.floor((duration % 1440) / 60)
    }
    return split
  },
  getDateTimeStringFromDate(date) {
    let dateString = DateTime.fromJSDate(date)
      .setLocale('de')
      .toFormat('yyyy-MM-dd')

    let timeString = DateTime.fromJSDate(date)
      .setLocale('de')
      .toFormat('HH:mm:ss')

    return `${dateString}T${timeString}`
  },
  getCustomDateTimeStringFromDate(date) {
    let dateString = DateTime.fromJSDate(date)
      .setLocale('de')
      .toFormat('dd.MM.yyyy')

    let timeString = DateTime.fromJSDate(date)
      .setLocale('de')
      .toFormat('HH:mm')

    return `${dateString} ${timeString}`
  },
  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000)
  },
  getMinutesFromDuration(duration) {
    return (
      parseInt(duration.minutes) +
      parseInt(duration.hours) * 60 +
      parseInt(duration.days) * 24 * 60
    )
  },
  getMinutesDifference(start, end) {
    return DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start), 'minutes')
      .minutes
  },

  getDaysHoursMinutesFromDateDifference(start, end) {
    let diff = DateTime.fromJSDate(end)
      .diff(DateTime.fromJSDate(start), ['days', 'hours', 'minutes', 'seconds'])
      .toObject()

    return {
      days: diff.days,
      hours: diff.hours,
      minutes: diff.minutes
    }
  },
  addTimeToDate(origDate, days, hours, minutes) {
    return DateTime.fromJSDate(new Date(origDate))
      .plus({
        days,
        hours,
        minutes
      })
      .toJSDate()
  },

  daysBetween(start, end) {
    return this.getDaysHoursMinutesFromDateDifference(start, end).days
  },
  isBeforeToday(date) {
    var d = new Date(date)
    var today = new Date()
    return d <= today
  },

  isValidDate(day, month, year) {
    day = parseInt(day)
    month = parseInt(month)
    year = parseInt(year)

    if (year < 0) return false
    if (year < 1000) return false
    if (month < 1 || month > 12) return false
    if (day < 1) return false

    // Check for February and leap years
    if (month === 2) {
      if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        // Leap year
        return day <= 29
      } else {
        // Non-leap year
        return day <= 28
      }
    }

    // Check for months with 30 days
    if ([4, 6, 9, 11].includes(month)) {
      return day <= 30
    }

    // All other months have 31 days
    return day <= 31
  },

  isValidTime(hour, minute) {
    hour = parseInt(hour)
    minute = parseInt(minute)

    if (isNaN(hour) || isNaN(minute)) return false
    if (hour < 0 || hour > 23) return false
    if (minute < 0 || minute > 59) return false

    return true
  }
}
