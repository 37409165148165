import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import HomeService from '@/services/home/homeService.js';

/* Components */
import { GridLayout, GridItem } from 'vue-grid-layout';
import SearchComponent from '@/components/home/SearchComponent.vue';
import WidgetOverviewComponent from '@/components/home/WidgetOverviewComponent.vue';

// General
import ClockComponent from '@/components/home/ClockComponent.vue';

// CRM
import DocumentsLast from '@/components/home/DocumentsLast.vue';
import InteractionsLast from '@/components/home/InteractionsLast.vue';

// Telemetry
import TelemetryFailedLoginsComponent from '@/components/home/TelemetryFailedLoginsComponent.vue';

// Calendar
import AgendaComponent from '@/components/home/AgendaComponent.vue';

// Statistics
import CustomerTodayComponent from '@/components/home/CustomerTodayComponent.vue';
import OpenPositions from '@/components/home/OpenPositions.vue';
import MonthStatisticComponent from '@/components/home/MonthStatisticComponent.vue';
import TurnoverStatisticComponent from '@/components/home/TurnoverStatisticComponent.vue';
import YearStatisticComponent from '@/components/home/YearStatisticComponent.vue';

// Task
import TasksComponent from '@/components/home/TasksComponent.vue';
export default {
  name: 'Home',
  components: {
    WidgetOverviewComponent,
    GridLayout,
    GridItem,
    SearchComponent,
    ClockComponent,
    AgendaComponent,
    CustomerTodayComponent,
    TelemetryFailedLoginsComponent,
    DocumentsLast,
    TasksComponent,
    YearStatisticComponent,
    MonthStatisticComponent,
    InteractionsLast,
    TurnoverStatisticComponent,
    OpenPositions
  },
  data() {
    return {
      customLabels: {},
      layout: [],
      //Breakpoints defined for responsive layout, the parameter represents the width of different devices:lg(large), md(medium), sm(small), xs(extra small).
      responsiveLayouts: {},
      defaultResponsiveLayout: {
        xxs: [{
          x: 0,
          y: 0,
          w: 2,
          h: 5,
          i: 'CLOCK',
          moved: false,
          minW: 1,
          minH: 5
        }, {
          x: 0,
          y: 5,
          w: 2,
          h: 6,
          i: 'TASKS',
          moved: false,
          minW: 2,
          minH: 5
        }, {
          x: 0,
          y: 11,
          w: 3,
          h: 4,
          i: 'APPOINTMENTS',
          moved: false,
          minW: 3,
          minH: 4
        }],
        md: [{
          x: 0,
          y: 5,
          w: 4,
          h: 4,
          i: 'TASKS',
          moved: false,
          minW: 4,
          minH: 3
        }, {
          x: 4,
          y: 0,
          w: 6,
          h: 9,
          i: 'APPOINTMENTS',
          moved: false,
          minW: 4,
          minH: 4
        }, {
          x: 0,
          y: 0,
          w: 4,
          h: 5,
          i: 'CLOCK',
          moved: false,
          minW: 2,
          minH: 5
        }],
        sm: [{
          x: 2,
          y: 4,
          w: 4,
          h: 4,
          i: 'TASKS',
          moved: false,
          minW: 3,
          minH: 4
        }, {
          x: 2,
          y: 0,
          w: 4,
          h: 4,
          i: 'APPOINTMENTS',
          moved: false,
          minW: 3,
          minH: 4
        }, {
          x: 0,
          y: 0,
          w: 2,
          h: 8,
          i: 'CLOCK',
          moved: false,
          minW: 2,
          minH: 5
        }],
        xs: [{
          x: 0,
          y: 5,
          w: 4,
          h: 5,
          i: 'TASKS',
          moved: false,
          minW: 4,
          minH: 4
        }, {
          x: 0,
          y: 10,
          w: 4,
          h: 4,
          i: 'APPOINTMENTS',
          moved: false,
          minW: 4,
          minH: 4
        }, {
          x: 0,
          y: 0,
          w: 4,
          h: 5,
          i: 'CLOCK',
          moved: false,
          minW: 2,
          minH: 5
        }],
        lg: [{
          x: 7,
          y: 0,
          w: 5,
          h: 5,
          i: 'TASKS',
          moved: false,
          minW: 4,
          minH: 4
        }, {
          x: 3,
          y: 0,
          w: 4,
          h: 5,
          i: 'APPOINTMENTS',
          moved: false,
          minW: 3,
          minH: 4
        }, {
          x: 0,
          y: 0,
          w: 3,
          h: 5,
          i: 'CLOCK',
          moved: false,
          minW: 2,
          minH: 5
        }]
      },
      currentBreakPoint: '',
      greet: ['Guten Morgen', 'Guten Morgen', 'Guten Tag', 'Guten Abend'][parseInt(new Date().getHours() / 24 * 4)],
      widgetList: {},
      gridLayoutKey: 0
    };
  },
  methods: {
    breakpointChangedEvent: function (newBreakpoint, newLayout) {
      this.responsiveLayouts[newBreakpoint] = newLayout;
      this.currentBreakPoint = newBreakpoint;
    },
    getWidgetTitle(id) {
      let displayName = id;
      Object.keys(this.widgetList).forEach(widgetCategory => {
        if (displayName !== id) return; // don't check in following categories when name is already found
        this.widgetList[widgetCategory].forEach(widget => {
          if (widget.widgetID === id) {
            displayName = widget.displayName;
            return;
          }
        });
      });
      return displayName;
    },
    async toggleGridEditable() {
      //save grid
      if (this.editingLayout) await this.saveWidgetLayout();
      this.$store.commit('SET_EDITING_LAYOUT', !this.editingLayout);
    },
    addWidget(widgetId) {
      Object.keys(this.responsiveLayouts).forEach(breakpoint => {
        let layout = this.responsiveLayouts[breakpoint];

        // find highest y value for x = 0
        let yArray = [];
        layout.filter(o => o.x === 0).forEach(widget => {
          yArray.push(widget.y + widget.h);
        });
        const y = Math.max(...yArray);
        layout.push({
          x: 0,
          y: y,
          w: 4,
          h: 4,
          minW: this.minSizes[breakpoint][widgetId].minW,
          minH: this.minSizes[breakpoint][widgetId].minH,
          i: widgetId
        });
      });
      this.layout = this.responsiveLayouts[this.currentBreakPoint];
    },
    editWidgets() {
      const activeWidgetIds = this.layout.map(o => o.i);
      Object.keys(this.widgetList).forEach(widgetCategory => {
        const activeWidgets = this.widgetList[widgetCategory].filter(o => o.active).map(o => o.widgetID);

        // add all new widgets
        activeWidgets.forEach(widgetId => {
          if (!activeWidgetIds.includes(widgetId)) {
            this.addWidget(widgetId);
          }
        });
        const disabledWidgets = this.widgetList[widgetCategory].filter(o => !o.active).map(o => o.widgetID);

        // remove widgets from active ones that have been disabled in widget store
        disabledWidgets.forEach(widgetId => {
          if (activeWidgetIds.includes(widgetId)) {
            this.removeWidget(widgetId);
          }
        });
      });
    },
    removeWidget(widgetId) {
      // remove widget from layout
      this.layout = this.layout.filter(l => l.i != widgetId);

      // deactivate widget
      Object.keys(this.widgetList).forEach(category => {
        this.widgetList[category].forEach(widget => {
          if (widget.widgetID == widgetId) {
            widget.active = false;
            return;
          }
        });
      });
      Object.keys(this.responsiveLayouts).forEach(breakpoint => {
        let layout = this.responsiveLayouts[breakpoint];
        this.responsiveLayouts[breakpoint] = layout.filter(l => l.i != widgetId);
      });
    },
    async resetWidgetLayout() {
      this.responsiveLayouts = {
        ...this.defaultResponsiveLayout
      };
      this.layout = this.defaultResponsiveLayout[this.currentBreakPoint];

      // update active status of widgets
      const activeWidgetIds = this.layout.map(o => o.i);
      Object.keys(this.widgetList).forEach(widgetCategory => {
        const activeWidgets = this.widgetList[widgetCategory].filter(o => o.active).map(o => o.widgetID);

        // deactivate widgets that aren't present in the layout
        activeWidgets.forEach(widgetId => {
          if (!activeWidgetIds.includes(widgetId)) {
            this.widgetList[widgetCategory].forEach(widget => {
              if (widgetId == widget.widgetID) {
                widget.active = false;
              }
            });
          }
        });
      });
      this.$store.commit('SET_EDITING_LAYOUT', !this.editingLayout);
      // rerenders the layout component
      this.gridLayoutKey++;
      await HomeService.saveWidgetLayout({});
    },
    async saveWidgetLayout() {
      const currentBreakPoint = this.currentBreakPoint;
      if (JSON.stringify(this.responsiveLayouts) === '{}') return;
      if (this.layout.length === 0) return;
      if (this.responsiveLayouts == this.defaultResponsiveLayout) {
        // reset layout to default one
        return await HomeService.saveWidgetLayout({});
      }
      this.responsiveLayouts[currentBreakPoint] = this.layout;
      return await HomeService.saveWidgetLayout(this.responsiveLayouts);
    },
    async getWidgetLayout() {
      const widgetLayout = await HomeService.getWidgetLayout();
      if (Object.keys(widgetLayout).length === 0) {
        this.responsiveLayouts = {
          ...this.defaultResponsiveLayout
        };
      } else {
        this.responsiveLayouts = widgetLayout;
      }
      this.layout = this.responsiveLayouts[this.currentBreakPoint];
    },
    async getWidgetList() {
      const availableWidgets = await HomeService.getAvailableWidgets();
      // add 'active' property to each widget and check if widget is active
      Object.keys(availableWidgets).forEach(widgetCategory => {
        availableWidgets[widgetCategory] = availableWidgets[widgetCategory].map(element => {
          let activeState = false;
          this.layout.forEach(layoutWidget => {
            if (layoutWidget.i === element.widgetID) {
              activeState = true;
              return;
            }
          });
          return {
            ...element,
            description: element.description,
            active: activeState
          };
        });

        // make widget labels from request available to store
        availableWidgets[widgetCategory].forEach(widget => {
          this.$store.commit('SET_WIDGET_LABEL', {
            id: widget.widgetID,
            label: widget.displayName
          });
        });
      });
      this.widgetList = availableWidgets;
    },
    async checkWidgetPermissions() {
      let widgetsRemoved = false;
      Object.keys(this.responsiveLayouts).forEach(breakpoint => {
        this.responsiveLayouts[breakpoint].forEach(widget => {
          // don't remove widgets contained in the default layout
          if (widget.i === 'CLOCK' || widget.i === 'APPOINTMENTS' || widget.i === 'TASKS') return;
          const appId = this.getAppIdForWidgetId(widget.i);

          // if permission for app exists don't proceed
          if (this.$store.getters.userHasAppById(appId)) return;
          // remove widgets from layout
          console.error(`Access denied: ${appId}>${widget.i}`);
          this.removeWidget(widget.i);
          widgetsRemoved = true;
        });
      });

      // save Layout when Widgets were removed
      if (widgetsRemoved) {
        await this.saveWidgetLayout();
      }
    },
    getAppIdForWidgetId(widgetId) {
      let app = undefined;
      Object.keys(this.widgetList).forEach(appId => {
        if (app != undefined) return;
        this.widgetList[appId].forEach(widget => {
          if (app != undefined) return;
          if (widgetId == widget.widgetID) {
            app = widget.appId;
          }
        });
      });
      return app;
    },
    async checkMinDimensionsForWidgets() {
      let minDimUpdated = false;
      Object.keys(this.responsiveLayouts).forEach(breakpoint => {
        const newDimLayout = this.setMinimumDimensionsForWidget(breakpoint, this.responsiveLayouts[breakpoint]);

        // compare the current and updated object
        if (JSON.stringify(newDimLayout) !== JSON.stringify(this.responsiveLayouts[breakpoint])) {
          minDimUpdated = true;
          this.responsiveLayouts[breakpoint] = newDimLayout;
        }
      });

      // prevent saving an unchanged layout
      if (minDimUpdated) {
        await this.saveWidgetLayout();
      }
    },
    setMinimumDimensionsForWidget(breakpoint, layout) {
      return layout.map(widget => {
        if (this.minSizes[breakpoint][widget.i].minW !== widget.minW || this.minSizes[breakpoint][widget.i].minH !== widget.minH) {
          console.info('Widget minimum dimensions updated');
          return {
            ...widget,
            minW: this.minSizes[breakpoint][widget.i].minW,
            minH: this.minSizes[breakpoint][widget.i].minH
          };
        }
        return {
          ...widget
        };
      });
    }
  },
  computed: {
    dashboardEditable() {
      if (this.settings_dashboard !== undefined) {
        const layoutEditEnabled = this.settings_dashboard.filter(s => s.valueName == 'layoutEditEnabled')[0].value;
        return process.env.VUE_APP_FEATURE_DASHBOARD_EDITABLE === 'true' && layoutEditEnabled == '1';
      } else {
        return false;
      }
    },
    ...mapGetters(['user', 'settings_dashboard', 'company', 'widgetLabels', 'minSizes', 'editingLayout'])
  },
  async mounted() {
    this.$store.dispatch('loadVisibleTabs');
    this.$store.dispatch('loadUserSettings');
    await this.getWidgetLayout();
    await this.getWidgetList();
    await this.checkWidgetPermissions();
    await this.checkMinDimensionsForWidgets();
  }
};