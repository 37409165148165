import axios from 'axios'

export default {
  getGenders() {
    return axios.get(`/v1/util/genders`)
  },
  predictGenderBasedOnFirstName(firstName) {
    return axios.get('v1/util/autofill/gender', {
      params: { name: firstName }
    })
  }
}
