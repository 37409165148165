import "core-js/modules/es.array.push.js";
import NotificationComponent from '@/components/core/NotificationComponent.vue';
import { mapGetters } from 'vuex';
import SearchComponent from '@/components/home/SearchComponent.vue';
export default {
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  components: {
    NotificationComponent,
    SearchComponent
  },
  computed: {
    ...mapGetters(['loggedIn', 'user', 'user_img_base64']),
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true;
      }
      return false;
    },
    searchBarVisible() {
      if (this.$route.name == 'Home' || this.$route.name == 'Login' || !this.loggedIn) {
        return false;
      } else {
        return true;
      }
    },
    miniNavbar() {
      if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'md') {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal);
    }
  },
  methods: {
    openDrawer() {
      this.$emit('drawerChange', true);
    },
    isMobile() {
      if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm') {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      this.$store.dispatch('logout');
    },
    navigateToHome() {
      this.$router.push('/');
    }
  }
};