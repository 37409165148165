import axios from 'axios'

export async function getAppCategories() {
  const response = await axios.get(`/v1/categories`)

  if (response.status != 200) {
    throw axios.AxiosError.ERR_BAD_REQUEST
  } else {
    return response.data
  }
}
