import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import dateTimeService from '@/services/cal/dateTimeService.js';
//import NameSearchHelpDialog from '@/components/crm/addresses/NameSearchHelpDialog.vue'
import AddressSearch from '@/components/calendar_v2/AddressSearch.vue';
import ObjectList from '@/components/calendar_v2/ObjectList.vue';
import Loading from '@/components/calendar_v2/Loading.vue';
import DateTimePicker from '@/components/calendar_v2/DateTimePicker.vue';
import calendarService from '@/services/cal/calendarService.js';
import ForceUpdateDialog from './ForceUpdateDialog.vue';
import AddressPicturesDialog from '@/components/crm/addresses/AddressPicturesDialog.vue';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import ReservedDataDialog from '@/components/calendar_v2/ReservedDataDialog.vue';
import SerialAppointmentDialog from '@/components/calendar_v2/SerialAppointmentDialog.vue';
import SerialReservedDataDialog from '@/components/calendar_v2/SerialReservedDataDialog.vue';
import { DateTime } from 'luxon';
import axios from 'axios';
export default {
  computed: {
    ...mapState({
      scopes: state => state.calendarInformation.scopes,
      locations: state => state.calendarInformation.locations,
      types: state => state.calendarInformation.types,
      states: state => state.calendarInformation.states,
      addresses: state => state.calendarInformation.addresses,
      objects: state => state.calendarInformation.objects,
      resources: state => state.calendarInformation.resources
    }),
    currentDisplay() {
      return this.$vuetify.breakpoint.name;
    }
  },
  components: {
    //NameSearchHelpDialog,
    AddressSearch,
    Loading,
    ObjectList,
    DateTimePicker,
    // eslint-disable-next-line vue/no-unused-components
    calendarService,
    AddressPicturesDialog,
    ErrorDialog,
    ReservedDataDialog,
    SerialAppointmentDialog,
    SerialReservedDataDialog,
    ForceUpdateDialog
  },
  data: () => ({
    result: false,
    doubleClicked: false,
    editAppointment: false,
    appointment: [],
    changeToAllDayCalled: false,
    days: Array.from({
      length: 32
    }, (_, i) => i),
    hours: Array.from({
      length: 24
    }, (_, i) => i),
    minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    durationSplit: [],
    startPicker: true,
    startDate: '',
    endDate: '',
    startPickerMenu: false,
    endPickerMenu: false,
    searchText: '',
    dateTimeService,
    endDateString: '',
    startDateString: '',
    expansionPanelModel: [0],
    minimumDate: new Date('01.01.1970').toISOString(),
    maximumDate: new Date('01.01.2123').toISOString(),
    preventSave: false,
    type: '',
    showingDefDuration: false,
    startDateTimeFromMouseClick: '',
    category: [],
    formValid: false,
    rules: {
      required: [value => !!value || 'Pflichtfeld']
    },
    currentAddress: [],
    showAddressPicturesDialog: false,
    failMessage: 'Pflichtfelder ausfüllen',
    errorDialog: false,
    isSerial: false,
    isCheckbox: false
  }),
  methods: {
    addAppointmentPlusUnchecked() {
      console.log("addAppointmentPlusUnchecked");
      this.appointment.allDay = false;
      this.changeToAllDayCalled = false;
    },
    createDayEventChecked() {
      console.log("createDayEventChecked");
      this.appointment.allDay = true;
      this.changeToAllDayCalled = true;
    },
    normalAppointmentAllDayUnchecked() {
      console.log("normalAppointmentAlldayUnchecked");
      this.appointment.allDay = false;
      this.changeToAllDayCalled = false;
    },
    closeSerialReservedDataDialog() {
      this.$refs.reservedSerial.serialReservedDataDialog = false;
      this.editAppointment = false;
    },
    open(type, date = '', category = '', isSerial = false) {
      this.isSerial = isSerial;
      this.type = type;
      this.appointment.allDay = false;
      if (type == 'edit') {
        console.warn('Nun wird editiert!');
        this.durationSplit = dateTimeService.getDurationSplit(this.appointment.duration);
        this.startDate = dateTimeService.getDateStringFromDateTimeString(this.appointment.start);
        this.endDate = dateTimeService.getDateStringFromDateTimeString(this.appointment.end);
        if (this.appointment.allDay) {
          this.durationSplit.days += 1;
          var index = this.days.indexOf(0);
          if (index !== -1) this.days.splice(index, 1);
        }
        this.maximumDate = new Date(this.appointment.end).toISOString();
        this.minimumDate = new Date(this.appointment.start).toISOString();
        this.recalculateDateTime('init');
        this.showingDefDuration = false;
        this.searchText = '';
      } else if (type == 'add' && date === '') {
        this.recalculateDateTime('addInit');
      } else if (type == 'add' && date != '' && category === '') {
        this.startDateTimeFromMouseClick = date;
        this.recalculateDateTime('addInitWithStartDate');
      } else if (type == 'add' && date != '' && category != '') {
        console.warn('ja - nein?');
        this.startDateTimeFromMouseClick = date;
        this.category = category;
        this.recalculateDateTime('addInitWithStartDateAndCategory');
      } else if (type == 'show') {
        this.durationSplit = dateTimeService.getDurationSplit(this.appointment.duration);
        this.startDate = dateTimeService.getDateStringFromDateTimeString(this.appointment.start);
        this.endDate = dateTimeService.getDateStringFromDateTimeString(this.appointment.end);
        this.recalculateDateTime('init');
      }
      this.editAppointment = true;
    },
    close() {
      this.editAppointment = false;
    },
    edit(reservedAppointment = null, option = null) {
      if (this.$refs.form.validate()) {
        if (this.isSerial) {
          calendarService.updateSerialAppointment(this.appointment).catch(error => {
            if (error.response.status == 409) {
              console.log('Reserved');
              this.$refs.reservedSerial.open(error.response.data, 'edit');
            }
            if (error.response.status == 422) {
              console.log('Conflict');
              this.$refs.forceUpdate.open(error.response.data, true);
            }
          }).then(response => {
            if (response.status >= 200 && response.status < 300) {
              this.editAppointment = false;
              this.$toast.success('Terminserie wurde gespeichert');
            }
          });
        } else {
          if (reservedAppointment === null && option === null) {
            axios.put(`/v1/calv2/appointments/update`, this.appointment).then(() => {
              if (this.$refs.reserved.reservedDataDialog == false) {
                this.editAppointment = false;
                this.$toast.success('Termin wurde gespeichert');
              }
            }).catch(error => {
              if (error.response.status == 409) {
                console.log('Reserved');
                this.$refs.reserved.open(error.response.data, 'edit');
              }
              if (error.response.status == 422) {
                console.log('Conflict');
                this.$refs.forceUpdate.open(error.response.data);
              }
            });
          } else {
            reservedAppointment.conflictResolution = option;
            axios.put('/v1/calv2/appointments/update', reservedAppointment).catch(error => {
              if (error.response.status == 422) {
                console.log('Conflict');
                this.$refs.forceUpdate.open(error.response.data);
              }
            }).then(() => {
              this.editAppointment = false;
              this.$toast.success('Termin wurde gespeichert');
            });
          }
        }
      } else {
        this.errorDialog = true;
      }
    },
    add(reservedAppointment = null, option = null) {
      if (this.$refs.form.validate()) {
        if (reservedAppointment === null && option === null) {
          calendarService.addAppointment(this.appointment).catch(reserved => {
            this.$refs.reserved.open(reserved, 'add');
          }).then(() => {
            console.warn(this.appointment);
            if (this.$refs.reserved.reservedDataDialog == false) {
              this.editAppointment = false;
              this.$toast.success('Termin wurde erstellt');
            }
          });
        } else {
          reservedAppointment.conflictResolution = option;
          calendarService.addAppointment(reservedAppointment).then(() => {
            console.warn(reservedAppointment);
            this.editAppointment = false;
            this.$toast.success('Termin wurde erstellt');
          });
        }
      } else {
        this.errorDialog = true;
      }
    },
    searchAddresses() {
      this.$refs.loading.open('Adressen werden geladen ...');
      this.$store.dispatch('loadAddresses', this.searchText).then(() => {
        this.appointment.addresses.forEach(element => {
          const i = this.addresses.findIndex(a => a.key.lfdnr === element.key.lfdnr);
          if (i > -1) {
            this.addresses.splice(i, 1);
          }
        });
        this.$refs.loading.close();
        this.$refs.addressSearch.open(this.addresses);
      });
    },
    addAddress(item) {
      if (!this.appointment.addresses.includes(item)) {
        this.appointment.addresses.push(item);
      }
    },
    addObject(item) {
      if (!this.appointment.objects.includes(item)) {
        this.appointment.objects.push(item);
      }
    },
    removeAddress(item) {
      this.appointment.addresses.splice(this.appointment.addresses.indexOf(item), 1);
    },
    removeObject(item) {
      this.appointment.objects.splice(this.appointment.objects.indexOf(item), 1);
    },
    openObjectList() {
      this.$refs.loading.open('Objekte werden geladen...');
      this.$store.dispatch('loadObjects', this.appointment).then(() => {
        this.appointment.objects.forEach(element => {
          const i = this.objects.findIndex(a => a.key.code === element.key.code);
          if (i > -1) {
            this.objects.splice(i, 1);
          }
        });
        this.$refs.loading.close();
        this.$refs.objectList.open(this.objects);
      });
    },
    openDateTimePicker(type) {
      if (type === 'start') {
        this.$refs.startPicker.date = dateTimeService.getDateStringFromDateTimeString(this.appointment.start);
        if (!this.appointment.wholeDay) {
          this.$refs.startPicker.time = dateTimeService.getTimeStringFromDateTimeString(this.appointment.start);
        }
        this.$refs.startPicker.minimumDate = this.minimumDate;
        this.$refs.startPicker.maximumDate = this.maximumDate;
        this.$refs.startPicker.mode = 'start';
        this.$refs.startPicker.open(this.appointment.allDay);
      }
      if (type === 'end') {
        this.$refs.endPicker.date = dateTimeService.getDateStringFromDateTimeString(this.appointment.end);
        if (!this.appointment.wholeDay) {
          this.$refs.endPicker.time = dateTimeService.getTimeStringFromDateTimeString(this.appointment.end);
        }
        this.$refs.endPicker.minimumDate = this.minimumDate;
        this.$refs.endPicker.maximumDate = this.maximumDate;
        this.$refs.endPicker.mode = 'end';
        this.$refs.endPicker.open(this.appointment.allDay);
      }
    },
    saveStart(date, time) {
      if (time != null) {
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(new Date(date + ' ' + time));
      } else {
        //console.log(date)
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(new Date(date));
      }
      this.minimumDate = new Date(this.appointment.start).toISOString();
      this.recalculateDateTime('start');
    },
    saveEnd(date, time) {
      if (time != null) {
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(new Date(date + ' ' + time));
      } else {
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(new Date(date));
      }
      this.maximumDate = new Date(this.appointment.end).toISOString();
      this.recalculateDateTime('end');
    },
    recalculateDateTime(elem) {
      if (elem == 'allDay') {
        this.durationSplit.days = dateTimeService.daysBetween(new Date(this.appointment.start), new Date(this.appointment.end));
        this.durationSplit.days = 1;
        this.durationSplit.hours = 0;
        this.durationSplit.minutes = 0;
        this.recalculateDateTime('duration');
        if (this.appointment.allDay) {
          var index = this.days.indexOf(0);
          if (index !== -1) this.days.splice(index, 1);
        } else {
          this.days.unshift(0);
        }
      }
      if (elem == 'duration' || elem == 'start') {
        var days = !this.appointment.allDay ? this.durationSplit.days : this.durationSplit.days - 1;
        this.appointment.end = dateTimeService.getDateTimeStringFromDate(dateTimeService.addTimeToDate(new Date(this.appointment.start), days, this.durationSplit.hours, this.durationSplit.minutes));
      } else if (elem == 'end') {
        this.durationSplit = dateTimeService.getDaysHoursMinutesFromDateDifference(new Date(this.appointment.start), new Date(this.appointment.end));
      }

      // Formatierung für ganztägige und nicht-ganztägige Termine
      if (this.appointment.allDay) {
        this.endDateString = DateTime.fromISO(this.appointment.end).toLocaleString(DateTime.DATE_MED);
        this.startDateString = DateTime.fromISO(this.appointment.start).toLocaleString(DateTime.DATE_MED);
      } else {
        this.endDateString = DateTime.fromISO(this.appointment.end).toLocaleString(DateTime.DATETIME_MED);
        this.startDateString = DateTime.fromISO(this.appointment.start).toLocaleString(DateTime.DATETIME_MED);
      }
      if (elem == 'addInit') {
        var coeff = 1000 * 60 * 5;
        var date = new Date();
        var currentDate = new Date(Math.round(date.getTime() / coeff) * coeff);
        // heutiges Datum und Uhrzeit (gerundet auf 5min) wird als Start angegeben
        this.startDateString = dateTimeService.getGermanDateFormat(currentDate);
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(currentDate);
        // Standarddauer: 15 min
        this.appointment.duration = 15;
        this.durationSplit = dateTimeService.getDurationSplit(this.appointment.duration);
        this.recalculateDateTime('duration');
        this.maximumDate = new Date(this.appointment.end).toISOString();
        this.minimumDate = new Date(this.appointment.start).toISOString();
      } else if (elem == 'addInitWithStartDate') {
        this.startDateString = dateTimeService.getGermanDateFormat(this.startDateTimeFromMouseClick);
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(this.startDateTimeFromMouseClick);
        this.appointment.duration = 15;
        this.durationSplit = dateTimeService.getDurationSplit(this.appointment.duration);
        this.recalculateDateTime('duration');
        this.maximumDate = new Date(this.appointment.end).toISOString();
        this.minimumDate = new Date(this.appointment.start).toISOString();
      } else if (elem == 'addInitWithStartDateAndCategory') {
        this.startDateString = dateTimeService.getGermanDateFormat(this.startDateTimeFromMouseClick);
        this.appointment.start = dateTimeService.getDateTimeStringFromDate(this.startDateTimeFromMouseClick);
        this.appointment.duration = 15;
        this.durationSplit = dateTimeService.getDurationSplit(this.appointment.duration);
        this.recalculateDateTime('duration');
        this.maximumDate = new Date(this.appointment.end).toISOString();
        this.minimumDate = new Date(this.appointment.start).toISOString();
        switch (this.category.type) {
          case 'scope':
            this.appointment.scope = this.category.object;
            break;
          case 'location':
            this.appointment.location = this.category.object;
            break;
        }
      } else {
        if (this.appointment.allDay) {
          this.endDateString = DateTime.fromISO(this.appointment.end).toLocaleString(DateTime.DATE_MED);
          this.startDateString = DateTime.fromISO(this.appointment.start).toLocaleString(DateTime.DATE_MED);
        } else {
          this.endDateString = DateTime.fromISO(this.appointment.end).toLocaleString(DateTime.DATETIME_MED);
          this.startDateString = DateTime.fromISO(this.appointment.start).toLocaleString(DateTime.DATETIME_MED);
        }
      }
    },
    setDefDuration() {
      this.durationSplit = dateTimeService.getDurationSplit(this.appointment.type.defDuration);
      this.recalculateDateTime('duration');
      this.showingDefDuration = false;
    },
    checkForDoubleClick() {
      this.$emit('checkDoubleClick', result => {
        this.result = result;
      });
      return this.result;
    },
    changeToAllDay() {
      this.appointment.allDay = true;
      this.changeToAllDayCalled = true;
    },
    changeCheckBoxState() {
      if (this.changeToAllDayCalled) {
        this.recalculateDateTime('duration');
        this.durationSplit.days = 1;
        this.appointment.allDay = true;
        return true;
      }
      return false;
    },
    clearData() {
      this.appointment = {
        addresses: [],
        objects: [],
        resources: [],
        key: {
          firma: 1
        },
        scope: this.scopes[0],
        location: this.locations[0],
        type: this.types[0],
        state: this.states[0]
      };
      this.showingDefDuration = false;
      this.searchText = '';
    },
    showPictures(item) {
      this.currentAddress = item;
      console.warn(this.currentAddress);
      this.showAddressPicturesDialog = true;
    },
    closeAddressPicturesDialog() {
      this.showAddressPicturesDialog = false;
    },
    openSerialAppointmentDialog() {
      if (this.$refs.form.validate()) this.$refs.serial.open(this.appointment);else this.errorDialog = true;
    }
  }
};