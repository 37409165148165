import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import statisticsLogo from '@/assets/svg/lba-apps/LBA-Statistik.svg';
import calLogo from '@/assets/svg/lba-apps/LBA-Kalender.svg';
import crmLogo from '@/assets/svg/lba-apps/LBA-CRM.svg';
import devLogo from '@/assets/svg/lba-apps/LBA-DEV.svg';
import artikelLogo from '@/assets/svg/lba-apps/LBA-ArticleSearch.svg';
import businessLogo from '@/assets/svg/lba-apps/LBA-Business.svg';
import taskLogo from '@/assets/svg/lba-apps/LBA-Task.svg';
import settingsLogo from '@/assets/svg/lba-apps/LBA-Settings.svg';
import VersionChip from './VersionChip.vue';
import telemetryService from '@/services/core/telemetryService';
import { getAppCategories } from "@/services/core/appService";
export default {
  components: {
    VersionChip
  },
  props: {
    drawer: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    group: null,
    homeAppString: 'LBA-WEB',
    isNavbarOpen: false
  }),
  computed: {
    ...mapGetters(['loggedIn', 'user', 'user_img_base64', 'availableApps', 'lbaSettingsApps', 'company', 'availableApps', "appCategories"]),
    miniNavbar() {
      if (this.$vuetify.breakpoint.name == 'xs' || this.$vuetify.breakpoint.name == 'sm' || this.$vuetify.breakpoint.name == 'md') {
        return true;
      } else {
        return false;
      }
    },
    currentApplication() {
      // checks which lba-app matches the current router path. Reporting is outsourced in a watcher below.

      if (this.$route.path <= 1) return undefined;
      let routerSplitString = this.$route.path.substring(1).split('/');
      return this.availableApps.find(app => routerSplitString[0] == app.appUrl.substring(1));
    },
    loginButtonVisible() {
      if (!this.loggedIn && this.$route.name != 'Login') {
        return true;
      }
      return false;
    }
  },
  methods: {
    isMobile() {
      if (this.$vuetify.breakpoint.name == 'sm') {
        return true;
      } else {
        return false;
      }
    },
    routeIncludes(search) {
      if (this.$route.matched.some(route => route.path.includes(search))) {
        return true;
      }
      return false;
    },
    appImg(item) {
      if (item.appId == 3) {
        return statisticsLogo;
      } else if (item.appId == 2) {
        return calLogo;
      } else if (item.appId == 1) {
        return crmLogo;
      } else if (item.appId == 6) {
        return devLogo;
      } else if (item.appId == 7) {
        return artikelLogo;
      } else if (item.appId == 8) {
        return businessLogo;
      } else if (item.appId == 9) {
        return taskLogo;
      } else if (item.appId == 10) {
        return settingsLogo;
      }
      return require('@/assets/LBA_logo_big.png');
    },
    navigateToHome() {
      this.$router.push('/');
    },
    getAppsForCategoryId(id) {
      return this.$store.getters.getAppsForCategory(id);
    },
    getAppsWithSettings() {
      return this.$store.getters.getAppsWithSettings;
    }
  },
  watch: {
    drawer(newVal) {
      this.$emit('drawerChange', newVal);
      this.isNavbarOpen = newVal;
    },
    isNavbarOpen(newVal) {
      if (this.drawer !== newVal) this.$emit('drawerChange', newVal);
    },
    async currentApplication(newApp, oldApp) {
      if (newApp != undefined && JSON.stringify(newApp) != JSON.stringify(oldApp)) {
        telemetryService.reportAppAccessById(newApp.appId);
      }
    }
  },
  async mounted() {
    this.$store.dispatch('loadUserData');
    this.$store.dispatch('loadUserImageBase64');
    this.$store.dispatch('loadUserSettings');
    this.$store.dispatch('loadVisibleTabs');
    this.$store.dispatch('loadAppsAvailableForCurrentUser');
    let appCategories = await getAppCategories();
    this.$store.dispatch("setAppCategories", appCategories);
    this.isNavbarOpen = this.drawer;
    this.unwatch = this.$store.watch((state, getters) => getters.loggedIn, newValue => {
      if (newValue == true) {
        this.$store.dispatch('loadUserData');
        this.$store.dispatch('loadUserImageBase64');
        this.$store.dispatch('loadUserSettings');
      } else {
        this.$store.dispatch('clearUserData');
      }
    });
  },
  beforeDestroy() {
    this.unwatch();
  }
};