export default {
  props: {
    textColor: {
      type: String,
      default: 'default'
    },
    titleType: {
      type: String,
      default: 'default'
    },
    sideBar: {
      type: Boolean,
      default: true
    },
    bgColor: {
      type: String,
      default: 'grey'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked() {
      this.$emit('click');
    }
  }
};