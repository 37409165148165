import genderService from '@/services/util/genderService'

export default {
  state: {
    genders: []
  },
  mutations: {
    SET_GENDERS(state, genders) {
      state.genders = genders
    }
  },
  actions: {
    loadGenders({ commit }) {
      genderService
        .getGenders()
        .then(response => commit('SET_GENDERS', response.data))
    }
  },
  getters: {}
}
