import "core-js/modules/es.array.push.js";
import LBADialog from '@/components/ui/dialogs/LBADialog.vue';
export default {
  components: {
    LBADialog
  },
  props: {
    widgetList: Object
  },
  data: () => ({
    widgetOverviewVisible: false,
    selectedCategory: 0,
    widgetCategories: [],
    search: {},
    searchItems: [],
    selectedWidget: undefined
  }),
  methods: {
    open() {
      // reset to default
      this.selectedCategory = 0;
      this.searchItems = [];

      // load widgetCategories
      let widgetCategories = Object.keys(this.widgetList);
      widgetCategories.sort();
      this.widgetCategories = widgetCategories;

      // create array for autocomplete
      widgetCategories.forEach((category, i) => {
        this.widgetList[category].forEach(widget => {
          this.searchItems.push({
            text: widget.displayName,
            value: {
              categoryIndex: i,
              widget
            }
          });
        });
      });

      // open dialog
      this.widgetOverviewVisible = true;
    },
    showWidget() {
      if (this.search !== null) {
        this.selectedCategory = undefined;
        this.selectedWidget = this.search.widget;
      } else {
        this.selectedCategory = 0;
      }
    },
    filterWidgets(item, queryText, itemText) {
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.value.widget.description.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    closeDialog() {
      this.widgetOverviewVisible = false;
      this.$emit('save');
    },
    resetLayout() {
      this.$refs.approveLayoutResetDialog.closeDialog();
      this.widgetOverviewVisible = false;
      this.$emit('reset');
    }
  },
  watch: {
    selectedCategory(value) {
      if (value != undefined) {
        this.selectedWidget = undefined;
        this.search = '';
      }
    }
  }
};