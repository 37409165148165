import { DocumentClass } from './../../../models/business/BusinessClasses'

export default {
  state: {
    document: DocumentClass.getEmptyDocument()
  },
  mutations: {
    SET_DOCUMENT(state, newDocument) {
      state.document = newDocument
    }
  },
  actions: {
    setDocument({ commit }, newDocument) {
      commit('SET_DOCUMENT', newDocument)
    }
  }
}
