import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "scrollable": true,
      "width": 1200,
      "max-width": 1200,
      "persistent": true,
      "cancelButton": false
    },
    on: {
      "close": function ($event) {
        return _vm.closeDialog();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Dashboard Widget-Store ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VRow, {
          staticClass: "px-2 mx-0 my-0"
        }, [_c(VCol, {
          staticClass: "col-12 col-lg-3"
        }, [_c(VListItem, [_c(VListItemContent, {
          staticClass: "py-0"
        }, [_c(VListItemTitle, {
          staticClass: "text-h6 mb-2",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" Widget-Übersicht ")]), _c(VListItemSubtitle, [_c(VAutocomplete, {
          staticClass: "pt-1",
          attrs: {
            "label": "Durchsuchen",
            "outlined": "",
            "prepend-inner-icon": "search",
            "dense": "",
            "items": _vm.searchItems,
            "auto-select-first": true,
            "filter": _vm.filterWidgets
          },
          on: {
            "change": _vm.showWidget
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })], 1)], 1)], 1), _c(VList, {
          staticClass: "pt-0",
          attrs: {
            "dense": "",
            "nav": ""
          }
        }, [_c(VListItemGroup, {
          attrs: {
            "color": "primary"
          },
          model: {
            value: _vm.selectedCategory,
            callback: function ($$v) {
              _vm.selectedCategory = $$v;
            },
            expression: "selectedCategory"
          }
        }, _vm._l(_vm.widgetCategories, function (widgetCategory) {
          return _c(VListItem, {
            key: widgetCategory,
            attrs: {
              "link": ""
            }
          }, [_c(VListItemContent, [_c(VListItemTitle, [_vm._v(_vm._s(widgetCategory))])], 1)], 1);
        }), 1)], 1), _c(VExpansionPanels, [_c('LBAExpansionPanel', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex align-center"
              }, [_c(VIcon, [_vm._v("mdi-information")]), _c('span', {
                staticClass: "ms-2"
              }, [_vm._v("Information")])], 1)];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_vm._v(" Bitte beachten Sie, dass unterschiedliche Displaygrößen (Desktop, Tablet, Smartphone) jeweils ein eigenes Layout haben. Daher müssen Sie die eingefügten Widgets für jede Größe separat anordnen. ")];
            },
            proxy: true
          }])
        })], 1)], 1), _c(VCol, {
          staticClass: "col-12 col-lg-9"
        }, [_vm._l(_vm.widgetCategories, function (widgetCategory) {
          return _c('div', {
            key: widgetCategory
          }, [_vm.widgetCategories[_vm.selectedCategory] == widgetCategory ? _c(VContainer, [_c('p', {
            staticClass: "text-h4 font-weight-bold primary--text"
          }, [_vm._v(" " + _vm._s(widgetCategory) + " ")]), _c(VRow, _vm._l(_vm.widgetList[widgetCategory], function (widget) {
            return _c(VCol, {
              key: widget.widgetID,
              staticClass: "col-12 col-sm-6 d-flex"
            }, [_c(VCard, {
              staticClass: "border-left-primary-3 d-flex flex-column flex-grow-1",
              attrs: {
                "flat": "",
                "outlined": ""
              }
            }, [widget.image != undefined ? _c(VImg, {
              staticClass: "mx-2 mt-2",
              attrs: {
                "src": widget.image,
                "max-height": "125",
                "contain": ""
              }
            }) : _vm._e(), _c(VCardTitle, {
              staticClass: "font-weight-bold primary--text"
            }, [_vm._v(_vm._s(widget.displayName))]), widget.description != undefined ? _c(VCardSubtitle, {
              staticClass: "ms-1"
            }, [_vm._v(" " + _vm._s(widget.description) + " ")]) : _vm._e(), _c(VCardActions, {
              staticClass: "d-flex justify-end flex-grow-1 align-end"
            }, [_c(VBtn, {
              class: widget.active ? 'grey' : 'primary',
              attrs: {
                "icon": ""
              },
              on: {
                "click": function ($event) {
                  widget.active = !widget.active;
                }
              }
            }, [_c(VIcon, {
              attrs: {
                "color": "white"
              }
            }, [_vm._v(_vm._s(widget.active ? 'remove' : 'add'))])], 1)], 1)], 1)], 1);
          }), 1)], 1) : _vm._e()], 1);
        }), _vm.widgetCategories[_vm.selectedCategory] === undefined ? _c('div', [!_vm.selectedWidget ? _c(VContainer, {
          staticClass: "pt-0 ps-4"
        }, [_c('p', {
          staticClass: "text-h4 font-weight-bold primary--text"
        }, [_vm._v(" Keine Kategorie ")]), _c('p', [_vm._v(" Bitte wählen Sie eine Kategorie aus oder durchsuchen Sie den Widget-Store mithilfe der Suchleiste auf der linken Seite. ")])]) : _c(VContainer, {
          staticClass: "pt-0 ps-4"
        }, [_c('p', {
          staticClass: "text-h4 font-weight-bold primary--text"
        }, [_vm._v(" Suchergebnis ")]), _c(VCol, {
          staticClass: "col-12 col-md-6"
        }, [_c(VCard, {
          staticClass: "border-left-primary-3",
          attrs: {
            "flat": "",
            "outlined": ""
          }
        }, [_vm.selectedWidget.image != undefined ? _c(VImg, {
          staticClass: "mx-2 mt-2",
          attrs: {
            "src": _vm.selectedWidget.image,
            "max-height": "125",
            "contain": ""
          }
        }) : _vm._e(), _c(VCardTitle, {
          staticClass: "font-weight-bold primary--text"
        }, [_vm._v(_vm._s(_vm.selectedWidget.displayName))]), _vm.selectedWidget.description != undefined ? _c(VCardSubtitle, {
          staticClass: "ms-1"
        }, [_vm._v(" " + _vm._s(_vm.selectedWidget.description) + " ")]) : _vm._e(), _c(VCardActions, {
          staticClass: "d-flex justify-end"
        }, [_c(VBtn, {
          class: _vm.selectedWidget.active ? 'grey' : 'primary',
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              _vm.selectedWidget.active = !_vm.selectedWidget.active;
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "white"
          }
        }, [_vm._v(_vm._s(_vm.selectedWidget.active ? 'remove' : 'add'))])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 2)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('LBADialog', {
          ref: "approveLayoutResetDialog",
          attrs: {
            "dialogType": "error",
            "centeredContent": "",
            "persistent": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function ({
              on,
              attrs
            }) {
              return [_c('LBAButton', _vm._g(_vm._b({
                staticClass: "me-2",
                attrs: {
                  "buttonType": "error"
                }
              }, 'LBAButton', attrs, false), on), [_c(VIcon, [_vm._v("mdi-restart")]), _vm._v("Layout zurücksetzen ")], 1)];
            }
          }, {
            key: "title",
            fn: function () {
              return [_vm._v(" Achtung! ")];
            },
            proxy: true
          }, {
            key: "content",
            fn: function () {
              return [_c('span', {
                staticClass: "text-h6 text-center"
              }, [_vm._v("Sind sie sicher, dass Sie das Dashboard-Layout zurücksetzen wollen?")])];
            },
            proxy: true
          }, {
            key: "success",
            fn: function () {
              return [_c('LBAButton', {
                attrs: {
                  "buttonType": "error"
                },
                on: {
                  "click": function ($event) {
                    return _vm.resetLayout();
                  }
                }
              }, [_vm._v(" Ok ")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              return _vm.closeDialog();
            }
          }
        }, [_vm._v("Ok")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.widgetOverviewVisible,
      callback: function ($$v) {
        _vm.widgetOverviewVisible = $$v;
      },
      expression: "widgetOverviewVisible"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };