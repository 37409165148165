import "core-js/modules/es.array.push.js";
import HomeService from '@/services/home/homeService.js';
import { mapState } from 'vuex';
import dateTimeService from '@/services/cal/dateTimeService';
export default {
  data: () => ({
    customerFullTextSearchUrl: '/v1/dashboard/search',
    searchInputSync: '',
    searchOutputSearchLoading: false,
    searchOutputs: [],
    dateTimeService,
    controller: undefined
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  watch: {
    async searchInputSync(newValue) {
      if (newValue === '') {
        this.searchOutputs = [];
      }
      await this.searchTrigger();
    }
  },
  methods: {
    getCorrectNoDataText() {
      if (this.searchInputSync == null || this.searchInputSync.length === 0) {
        return 'Suchbegriff eingeben, um die Suche zu starten.';
      }
      if (this.searchInputSync.length > 0 && this.searchInputSync.length < 3) {
        return 'Geben Sie mindestens 3 Zeichen ein, um die Suche zu starten.';
      }
      if (this.searchOutputSearchLoading) {
        return 'Ergebnisse werden geladen...';
      } else {
        return 'Keine Einträge gefunden.';
      }
    },
    initiateSearchUI() {
      this.searchOutputSearchLoading = true;
      this.searchOutputs = [];
    },
    async searchFullText(query) {
      if (query == undefined) return;
      this.initiateSearchUI();
      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.');
      }
      this.controller = new AbortController();
      const searchResults = await HomeService.getSearchResults(this.customerFullTextSearchUrl, query, this.controller).catch(err => {
        console.log('error searching address: ' + err);
      });
      this.searchOutputs = searchResults;
      this.searchOutputSearchLoading = false;
    },
    async searchTrigger() {
      if (this.searchInputSync != null && this.searchInputSync != '' && String(this.searchInputSync).length > 2) {
        this.searchStartedOnce = true;
        await this.searchFullText(this.searchInputSync);
      }
    },
    openSearchItem(item) {
      switch (item.type) {
        case 'ADDRESS':
          this.$router.push({
            name: 'crmAddress',
            params: {
              lfdnr: item.id
            }
          });
          break;
        case 'DOCUMENT':
          this.$router.push({
            name: 'crmAddressDocumentDetailed',
            params: {
              lfdnr: item.object.addressLfdnr,
              wf_cid: item.object.wf_cid
            }
          });
          break;
        case 'ARTICLE':
          this.$router.push({
            name: 'Artikeldetails',
            params: {
              articleNumber: item.id
            }
          });
          break;
        default:
          alert('Dieses Feature muss noch implementiert werden.');
      }
    }
  }
};