import axios from 'axios'
import jwt from 'jsonwebtoken'
import { DateTime } from 'luxon'
import store from '@/store'
import router from '@/router'

const REFRESH_BEFORE_BEARER_EXPIRES_SECONDS = 59

export default {
  getBearerFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).bearer
    } catch (e) {
      return null
    }
  },

  getRefreshTokenFromLocalStorage() {
    try {
      return JSON.parse(localStorage.getItem('auth')).refreshToken
    } catch (e) {
      return null
    }
  },

  loginWithUsernameAndPassword(credentials) {
    return axios.post('/v1/login', credentials)
  },

  async renewBearerWithRefreshToken(refreshToken) {
    await axios
      .post('/v1/refresh', {
        refreshToken: refreshToken
      })
      .then(res => {
        store.dispatch('setAuthData', res.data)
        console.debug('File: auth.js, Msg: bearer renewed')
        this.postAuthActions()
      })
      .catch(() => {
        console.debug('File: auth.js, Msg: FAILED to renew bearer')
        store.dispatch('reAuth')
      })
  },

  async renewBearerWithRefreshTokenAndNavigateToPath(
    refreshToken,
    path,
    query
  ) {
    await this.renewBearerWithRefreshToken(refreshToken)

    delete query.refreshToken
    router.push({ path, query })
  },

  async writeAuthToLocalStorage(auth) {
    localStorage.setItem('auth', JSON.stringify(auth))
    try {
      let bearerExpiringTime = new DateTime.fromSeconds(
        jwt.decode(auth.bearer).exp
      )
      let jwtRenewInSeconds = Math.floor(
        bearerExpiringTime.diff(new DateTime.now(), ['seconds']).seconds -
          REFRESH_BEFORE_BEARER_EXPIRES_SECONDS
      )
      // setTimeout in Milliseconds
      setTimeout(() => {
        this.renewBearerWithRefreshToken(this.getRefreshTokenFromLocalStorage())
      }, jwtRenewInSeconds * 1000)
    } catch {
      console.debug('authService.js: bearer not set')
    }
  },
  logoutHandler() {
    console.log('clearing Intervals now')
    const highestIntervalId = setInterval(';')
    for (var i = 0; i <= highestIntervalId; i++) {
      clearInterval(i)
    }
  },

  postAuthActions() {
    // General Initialization
    store.dispatch('loadGenders')
  }
}
