import HomeService from '@/services/home/homeService.js';
import Widget from '@/components/home/Widget.vue';
export default {
  data: () => ({
    openPositions: [],
    headers: [{
      text: 'Jahr / Monat',
      value: 'monatJahr',
      align: 'start'
    }, {
      text: 'Offene Summe',
      value: 'betrag',
      align: 'end'
    }],
    search: ''
  }),
  components: {
    Widget
  },
  async mounted() {
    this.openPositions = await HomeService.getOpenPositions();
  },
  methods: {
    format(value) {
      var form = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR'
      });
      return form.format(value);
    }
  }
};