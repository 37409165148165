import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Widget', [_c(VList, {
    staticClass: "pt-0"
  }, _vm._l(_vm.interactions, function (action) {
    return _c(VListItem, {
      key: action.mDat,
      attrs: {
        "link": "",
        "to": {
          name: 'crmInteractions',
          params: {
            lfdnr: action.id.kdnr
          }
        }
      }
    }, [_c(VListItemIcon, {
      staticClass: "mr-4"
    }, [_c(VIcon, [_vm._v(" mdi-account-voice ")])], 1), _c(VDivider, {
      staticClass: "mr-4 my-2",
      attrs: {
        "vertical": ""
      }
    }), _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "d-flex justify-space-between"
    }, [_c('span', [_vm._v(_vm._s(action.keywords))])]), _c(VListItemSubtitle, {
      staticClass: "d-flex justify-space-between"
    }, [_c('span', [_vm._v(_vm._s(action.kdSortname))]), _c('span', [_vm._v(_vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(action.date)))])])], 1)], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };