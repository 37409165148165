import dateTimeService from '@/services/cal/dateTimeService.js';
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    dateTimeService
  },
  data: () => ({
    DateTimePicker: false,
    allDay: null,
    tab: 0,
    date: '',
    time: '',
    minimumDate: '',
    maximumDate: '',
    mode: '',
    dateString: '',
    hours: '',
    minutes: '',
    inputHour: '',
    // Eingabe-Stunden
    inputMinute: '',
    // Eingabe-Minuten
    isoTime: '',
    // ISO-Zeitstempel HH:mm
    blockSave: false,
    roundDownTime: '',
    roundUpTime: '',
    allowedStep: m => m % 5 === 0,
    hourRules: [v => /^\d{1,2}$/.test(v) || 'Ungültig', v => parseInt(v) >= 0 && parseInt(v) <= 23 || 'Ungültig'],
    minuteRules: [v => /^\d{1,2}$/.test(v) || 'Ungültig', v => parseInt(v) >= 0 && parseInt(v) <= 59 || 'Ungültig']
  }),
  methods: {
    open(allDay) {
      this.tab = 0;
      this.allDay = allDay;
      this.DateTimePicker = true;
      this.dateString = new Date(this.date).toLocaleString('de-DE', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
      if (allDay) {
        this.time = '';
      }
      if (this.mode == 'end') {
        this.maximumDate = undefined;
      } else if (this.mode == 'start') {
        this.minimumDate = undefined;
      }
      console.warn(this.minimumDate);
      console.warn(this.maximumDate);
    },
    save() {
      const timeRegex = /^(?:[01]\d|2[0-3]):(?:0[05]|1[05]|2[05]|3[05]|4[05]|5[05])$/gm;
      if (!this.allDay) {
        let test = timeRegex.test(this.time);
        if (!test) {
          console.log(this.time);
          this.blockSave = true;
          return;
        } else {
          this.blockSave = false;
        }
      }
      this.DateTimePicker = false;
      if (this.allDay) {
        this.$emit('save', this.date, null);
      } else {
        this.$emit('save', this.date, this.time);
      }
      console.log(this.date, this.time);
    },
    goToTime() {
      if (!this.allDay) this.tab = 1;
    },
    updateTimeStr() {
      this.time = this.hours + ':' + this.minutes;
    },
    roundToNearestFiveMinutes(roundingMode = 'up') {
      const timeString = this.time;
      // Parse the input time string to get hours and minutes
      const [hours, minutes] = timeString.split(':').map(Number);

      // Calculate the remainder when dividing minutes by 5
      const remainder = minutes % 5;

      // If remainder is 0, the time is already a multiple of 5, so return it as is
      if (remainder === 0) {
        return timeString;
      }

      // Calculate the minutes to add or subtract to reach the nearest multiple of 5
      let minutesAdjustment = 0;
      if (roundingMode === 'up') {
        minutesAdjustment = 5 - remainder;
      } else if (roundingMode === 'down') {
        minutesAdjustment = -remainder;
      }

      // Calculate the rounded time
      const roundedMinutes = (minutes + minutesAdjustment + 60) % 60;
      const roundedHours = (hours + Math.floor((minutes + minutesAdjustment) / 60) + 24) % 24;

      // Pad the hours and minutes with leading zeros if necessary
      const roundedHoursString = String(roundedHours).padStart(2, '0');
      const roundedMinutesString = String(roundedMinutes).padStart(2, '0');

      // Return the rounded time in HH:mm format
      return `${roundedHoursString}:${roundedMinutesString}`;
    },
    setRoundDown() {
      this.time = this.roundToNearestFiveMinutes('down');
      this.blockSave = false;
    },
    setRoundUp() {
      this.time = this.roundToNearestFiveMinutes('up');
      this.blockSave = false;
    }
  }
};