import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import VueViewer from 'v-viewer'
import setupInterceptors from './services/axios/setupInterceptors'
import './plugins/leaflet'
import './plugins/wysiwyg-editor'
import './plugins/apexcharts'
import './registerServiceWorker'
import 'vue-toastification/dist/index.css'
import 'viewerjs/dist/viewer.css'
import VueScrollactive from 'vue-scrollactive'
import VueSmartWidget from 'vue-smart-widget'
import VueGridLayout from 'vue-grid-layout'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField

import authService from '@/services/core/authService'

Vue.config.productionTip = false

setupInterceptors(store)
Vue.use(VueScrollactive)
Vue.use(VueViewer)
Vue.use(VueSmartWidget)
Vue.use(VueGridLayout)

// The magic is here
import './App.scss'

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
})

Vue.component('v-text-field', VTextField)

Vue.use(VCurrencyField, {
  locale: 'de-AT',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

startApp()

// async start function to enable waiting for refresh token call
async function startApp() {
  // attempt to auto refresh token before startup
  try {
    console.debug('refreshing bearer on startup')
    await authService.renewBearerWithRefreshToken(
      authService.getRefreshTokenFromLocalStorage()
    )
  } catch {
    console.log('refreshing token on startup failed')
    // catch error to start app on success or failure
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    mounted() {}
  }).$mount('#app')
}
