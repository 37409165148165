import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('Widget', [_c(VList, {
    staticClass: "pt-0"
  }, _vm._l(_vm.documents, function (doc) {
    return _c(VListItem, {
      key: doc.wf_cid,
      attrs: {
        "value": doc.wf_cid,
        "link": "",
        "to": {
          name: 'crmAddressDocumentDetailed',
          params: {
            lfdnr: doc.addressLfdnr,
            wf_cid: doc.wf_cid
          }
        }
      }
    }, [_c(VListItemIcon, {
      staticClass: "mr-4"
    }, [_c(VIcon, [_vm._v(" mdi-file-document ")])], 1), _c(VDivider, {
      staticClass: "mr-4 my-2",
      attrs: {
        "vertical": ""
      }
    }), _c(VListItemContent, [_c(VListItemTitle, {
      staticClass: "d-flex justify-space-between"
    }, [_c('span', [_vm._v(_vm._s(doc.shortType) + " " + _vm._s(doc.nr) + " ")]), _c('span', [_vm._v(" " + _vm._s(doc.state))])]), _c(VListItemSubtitle, {
      staticClass: "d-flex justify-space-between"
    }, [_c('span', [_vm._v(_vm._s(doc.addressKdSortname))]), _c('span', [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(doc.date)) + " ")])])], 1)], 1);
  }), 1), _vm.documents.length === 0 ? _c('h2', {
    staticClass: "text-center text-h5"
  }, [_vm._v(" Es wurden keine Dokumente gefunden ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };