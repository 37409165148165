import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('LBADialog', {
    attrs: {
      "max-width": "880px"
    },
    on: {
      "close": function ($event) {
        _vm.ObjectList = false;
      },
      "click-outside": function ($event) {
        _vm.ObjectList = false;
      },
      "keydown-esc": function ($event) {
        _vm.ObjectList = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Objekte ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c(VSimpleTable, [_c('tbody', [_c(VDataTable, {
          ref: "vuetable",
          staticClass: "elevation-1",
          attrs: {
            "headers": _vm.header,
            "items": _vm.objects,
            "items-per-page": 10
          },
          scopedSlots: _vm._u([{
            key: "no-data",
            fn: function () {
              return [_vm._v(" Keine Daten gefunden. ")];
            },
            proxy: true
          }, {
            key: "item.actions",
            fn: function ({
              item
            }) {
              return [_c(VBtn, {
                staticClass: "mr-2 col-xl-5",
                attrs: {
                  "fab": "",
                  "small": "",
                  "text": "",
                  "elevation": "2"
                },
                on: {
                  "click": function ($event) {
                    return _vm.addObject(item);
                  }
                }
              }, [_c(VIcon, {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-plus ")])], 1)];
            }
          }])
        }, [_vm._v(" >")])], 1)])];
      },
      proxy: true
    }, {
      key: "success",
      fn: function () {
        return [_c('LBAButton', {
          on: {
            "click": function ($event) {
              _vm.ObjectList = false;
            }
          }
        }, [_vm._v("Fertig")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ObjectList,
      callback: function ($$v) {
        _vm.ObjectList = $$v;
      },
      expression: "ObjectList"
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };