import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm._t("default"), _c(VFadeTransition, [_c(VOverlay, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editingLayout,
      expression: "editingLayout"
    }],
    attrs: {
      "absolute": "",
      "opacity": 0.5
    }
  })], 1)], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };