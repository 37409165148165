import axios from 'axios'

export default {
  state: {
    documents: []
  },
  mutations: {
    CLEAR_DOCUMENTS(state) {
      state.documents = []
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    }
  },
  actions: {
    async loadDocuments({ commit }, lfdnr) {
      commit('CLEAR_DOCUMENTS')
      await axios.get(`/v1/crm/addresses/${lfdnr}/documents`).then(response => {
        response.data.forEach(element => {
          element.printLoading = false
          element.name = `${element.shortType} ${element.nr}`
        })
        commit('SET_DOCUMENTS', response.data)
      })
    }
  },
  getters: {}
}
