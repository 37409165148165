export default {
  data: () => ({
    reservedDataDialog: false,
    reservedData: null,
    type: '',
    text: ''
  }),
  methods: {
    closeDialog() {
      this.reservedDataDialog = false; // Set reservedDataDialog to false to close the dialog
    },
    open(reservedData, type) {
      this.reservedData = reservedData;
      this.reservedDataDialog = true;
      this.type = type;
      if (type == 'add') this.text = 'Erstellen';else if (type == 'edit') this.text = 'Bearbeiten';
    },
    perform(option) {
      if (option === 'DONT_CREATE') {
        this.reservedDataDialog = false;
        this.$emit('closeParent');
      } else {
        if (this.type == 'add') this.$emit('addWithOption', this.reservedData.appointment, option);
        if (this.type == 'edit') {
          console.log('Reserved');
          this.$emit('editWithOption', this.reservedData.appointment, option);
        }
        this.reservedDataDialog = false;
      }
    }
  }
};