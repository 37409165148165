import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import axios from 'axios';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
export default {
  components: {
    ErrorDialog
  },
  props: {
    address: Object,
    picTypes: Array,
    appointmentKey: Object
  },
  data: () => ({
    opened: true,
    imageType: '0',
    img_prevurl: [],
    loadingPicUpload: false,
    ErrorPictureUpload: false,
    ErrorWrongFileExtension: false,
    SuccessSnackbarPicture: false,
    errorDialog: false,
    failMessage: 'Bilderupload fehlgeschlagen!'
  }),
  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },
    changePrev(e) {
      const file = e.target.files[0];
      this.img_prevurl = URL.createObjectURL(file);
    },
    handleFileUpload() {
      let file = this.$refs.file.files[0];
      var idxDot = file.name.lastIndexOf('.') + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png') {
        this.formDataFile = new FormData();
        this.formDataPicType = new FormData();
        this.formDataFile.append('file', file);
        console.warn(file.name);
        this.formDataPicType.append('picType', this.imageType);
      } else {
        this.ErrorWrongFileExtension = true;
        document.getElementById('file').value = '';
        this.img_prevurl = '';
      }
    },
    async submitFile() {
      this.handleFileUpload();
      this.loadingPicUpload = true;
      var formDataFile = this.formDataFile;

      //TODO: In appointment.js File verlagern
      var uploadResponse = null;
      try {
        var _this$address$lfdnr;
        uploadResponse = await axios.post('/v1/cal/addresses/pictures/upload', formDataFile, {
          headers: {
            ImageType: this.imageType
          }
        });
        console.log(uploadResponse);
        this.img_prevurl = '';
        this.loadingPicUpload = false;
        this.$toast.success('Foto wurde hochgeladen');
        //this.SuccessSnackbarPicture = true

        // Lädt Namen des Bildes Hoch
        let picNames = [];
        picNames.push(uploadResponse.data.picName);
        this.address.picNames = [...picNames];
        this.$store.dispatch('uploadImageNames', {
          key: {
            lfdnr: (_this$address$lfdnr = this.address.lfdnr) !== null && _this$address$lfdnr !== void 0 ? _this$address$lfdnr : this.address.key.lfdnr,
            firma: 1
          },
          picNames: this.address.picNames,
          picType: this.imageType,
          appointmentKey: this.appointmentKey
        }).then(response => {
          //alert('successfully uploaded ')
          this.$emit('picture-uploaded');
          console.log(response);
        });
      } catch (error) {
        if (error.response) {
          if (error.response.status == 500 || error.response.status == 400) {
            this.ErrorPictureUpload = true;
            this.loadingPicUpload = false;
          }
        }
        return;
      }
    }
  }
};