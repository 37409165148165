import axiosInstance from 'axios'
import router from '../../router'

const setup = store => {
  axiosInstance.interceptors.request.use(
    config => {
      const token = store.state.auth.bearer
      if (token && config.url != '/v1/login' && config.url != '/v1/refresh') {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    res => {
      return res
    },
    async err => {
      const originalRequest = err.config
      if (
        originalRequest.url !== '/v1/login' &&
        originalRequest.url !== '/v1/refresh'
      ) {
        // Access Token has expired
        if (
          err.response.status === 403 &&
          router.currentRoute.name !== 'Kein Zugriff'
        ) {
          router.replace({ name: 'Kein Zugriff' })
        } else if (
          err.response.status === 401 &&
          !originalRequest._retry &&
          !!store.state.auth.refreshToken
        ) {
          originalRequest._retry = true

          await axiosInstance
            .post('/v1/refresh', {
              refreshToken: store.state.auth.refreshToken
            })
            .then(res => {
              store.dispatch('setAuthData', res.data)
              return axiosInstance(originalRequest)
            })
            .catch(error => {
              console.debug(
                'File: setupInterceptors.js, Code: refreshToken expired'
              )
              store.dispatch('reAuth')
              return Promise.reject(error)
            })
        }
        /* if this is activated, on refresh the user will have to login again
        else if (originalRequest._retry) {
          store.dispatch('reAuth')
        }*/
      }

      // Reject again, so that error can be handled again
      return Promise.reject(err)
    }
  )
}

export default setup
