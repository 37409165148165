import "core-js/modules/es.array.push.js";
import HomeService from '@/services/home/homeService.js';
import Widget from '@/components/home/Widget.vue';
export default {
  data: function () {
    return {
      series: []
    };
  },
  components: {
    Widget
  },
  computed: {
    options: function () {
      return {
        dataLabels: {
          enabled: false
        },
        title: {},
        states: {
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              var form = new Intl.NumberFormat('de-AT', {
                style: 'currency',
                currency: 'EUR'
              });
              return form.format(value);
            }
          }
        },
        chart: {
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
          }
        }
      };
    }
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      const revenueData = await HomeService.getTurnoverRevenue();
      var types = [];
      var seriesWrapper = [];
      revenueData.forEach(element => {
        for (var prop in element.y) {
          if (!types.includes(prop)) types.push(prop);
        }
      });
      types.forEach(type => {
        var series = {
          name: '',
          data: []
        };
        series.name = type;
        var dataSeries = [];
        revenueData.forEach(element => {
          dataSeries.push({
            x: element.x,
            y: element.y[type]
          });
        });
        series.data = dataSeries;
        seriesWrapper.push(series);
      });
      this.series = seriesWrapper;
    }
  }
};